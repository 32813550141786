import Lottie from "lottie-react";
import React from "react";
import oneballtofive from "../oneballtofiveballs.json";

const RightContent = ({ inView2, isPlaying, lottieRef, rightRef }) => {
  return (
    <div className="right-content" ref={lottieRef}>
      <div
        className={`card border-anim ${inView2 ? "border-anim-add" : ""}`}
        ref={rightRef}
      >
        <div className="outline-bottom-left"></div>
        <div className="outline-left-top"></div>
        <Lottie
          animationData={oneballtofive}
          loop={isPlaying}
          autoplay={!isPlaying}
        />
        {/* <img src="/img/animation/cellmultiplication.gif" /> */}
        {/* <video autoPlay loop muted>
                      <source
                        src="img/animation/cellmultiplication.mp4"
                        type="video/mp4"
                      />
                    </video> */}
      </div>
    </div>
  );
};

export default RightContent;
