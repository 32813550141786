import Hero from "./section/Hero";
import JobList from "./section/JobList";
import CTA from "../../common/CTA";
import Header from "../../template/Header";
import PageLayout from "../../layout/PageLayout";
const Career = () => {
  return (
    <>
      <Header />
      <Hero />
      <JobList />
      <CTA />
      <PageLayout />
    </>
  );
};

export default Career;
