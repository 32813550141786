import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import PageLayout from "./components/layout/PageLayout";
import Header from "./components/template/Header";
import Home from "./components/views/home";
import Product from "./components/views/product";
import Science from "./components/views/science";
import Clinic from "./components/views/clinic";
import Resource from "./components/views/resource";
import Contact from "./components/views/contact";
import Terms from "./components/views/Terms";
import Privacy from "./components/views/Privacy";
import Career from "./components/views/career";
import SignUp from "./components/views/auth/signup";
import Login from "./components/views/auth/login";
import Forgot from "./components/views/auth/forgot";
import CheckEmail from "./components/views/auth/checkemail";
import ResetPassword from "./components/views/auth/ResetPassword";
import ResetSuccess from "./components/views/auth/ResetSuccess";
import ArticlesDetail from "./components/views/resource/section/Detail/ArticlesDetail";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import ScrollToTop from "./components/layout/ScrollToTop";
function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/science" element={<Science />} />
          <Route path="/clinic" element={<Clinic />} />
          <Route path="/resources" element={<Resource />} />
          <Route path="/blog-detail/:id" element={<ArticlesDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-condition" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/career" element={<Career />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/check-email" element={<CheckEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password-success" element={<ResetSuccess />} />
          <Route path="*" element={<h2>404 Not Found</h2>} />
        </Routes>
        {/* <PageLayout /> */}
      </Router>
    </>
  );
}

export default App;
