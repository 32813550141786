const Hero = () => {
  return (
    <>
      <section className="Hero-5">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Hero-Content gap-24">
                <div className="Frame-Route">
                  <div>
                    <img src="/img/svg/logo.svg" />
                  </div>
                  <div>
                    <h4>/Resources</h4>
                  </div>
                </div>
                <div className="Content">
                  <div>
                    <h4>Expert guides on all things genetics & fertility</h4>
                  </div>
                  <div>
                    <p>
                      Conversations with leading experts, help with planning
                      before pregnancy, and stories that help demystify the
                      world of genetics and where things are heading
                    </p>
                  </div>
                </div>
                <div className="Frame-Input">
                  <div className="input-group">
                    <input
                      id="email"
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Enter your email"
                    />
                  </div>
                  <button className="btn btn-back">Subscribe</button>
                </div>
                <p className="care">
                  We care about your data in our <a href="">privacy policy</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
