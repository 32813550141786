import Hero from "./section/Hero";
import CompanySlider from "./section/CompanySlider";
import ScreenFeature from "./section/ScreenFeature";
import ScreenDisease from "./section/ScreenDisease";
import EstimateDisease from "./section/EstimateDisease";
import Testimonial from "../../common/Testimonial";
import HowItWorks from "./section/HowItWorks";
import PricingTable from "../../common/PricingTable";
import Revolution from "./section/Revolution";
import Team from "../../common/Team";
import Investors from "../../common/Investors";
import Career from "./section/Career";
import FAQ from "../../common/FAQ";
import CTA from "../../common/CTA";
import PageLayout from "../../layout/PageLayout";
import Header from "../../template/Header";
const Home = () => {
  return (
    <>
      <Header />

      <Hero />
      <CompanySlider />
      <ScreenFeature />
      <ScreenDisease />
      <EstimateDisease />
      <Testimonial />
      <HowItWorks />
      <PricingTable />
      <Revolution />
      <Team />
      <Investors />
      <Career />
      <FAQ />
      <CTA />
      <PageLayout />
    </>
  );
};

export default Home;
