import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="Hero-1">
        <div className="Background">
          <div className="Background-Img">
            <video autoPlay loop muted playsInline>
              <source src="img/animation/output.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="Rectangle-One"></div>
          <div className="Rectangle-Two"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="Hero-Content wm-md-100 gap-24">
                  <div className="Frame-Route">
                    <div>
                      <img alt="img" src="/img/svg/logo.svg" />
                    </div>
                    <div>
                      <h4>/GENE</h4>
                    </div>
                  </div>
                  <div className="Content">
                    <div>
                      <h4>Whole genome embryo reports</h4>
                    </div>
                    <div>
                      <p>
                        Mitigate more risks with the world’s most advanced whole
                        genome screening for embryos
                      </p>
                    </div>
                  </div>
                  <div className="Frame-Input">
                    <div className="input-group">
                      <input
                        id="email"
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Email address"
                      />
                      <span className="input-group-addon">
                        <img src="/img/svg/check.svg" />
                      </span>
                    </div>
                    <Link to="/signup" className="btn btn-back">
                      Get Access
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
