import Accordion from "react-bootstrap/Accordion";
import Lottie from "lottie-react";
import React, { useState, useEffect } from "react";
import animationData from "./jumpingBall.json";
import { Link } from "react-router-dom";
const CTA = () => {
  return (
    <>
      <section className="CTA">
        <div
          className="container show-web hide-mobile"
          style={{ maxWidth: "1280px", padding: "0px 32px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="layout">
                <div className="left-content">
                  <div className="d-inline-flex align-items-center">
                    <div className="bg-label-circle"></div>
                    <div className="bg-label-heading">
                      <label>CTA Text</label>
                    </div>
                  </div>
                  <div className="content">
                    <div className="Heading">
                      <h4>Precise whole genome embryo reports </h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Mitigate more risks with the world’s most advanced whole
                        genome screening for embryos
                      </p>
                    </div>
                    <div
                      className="gap-md d-flex align-items-center"
                      style={{ marginTop: "40px" }}
                    >
                      <Link to="/login" className="btn btn-outline">
                        Log in
                      </Link>
                      <Link to="/signup" className="btn btn-back">
                        Get Access
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="right-content">
                  <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container hide-web show-mobile"
          style={{ maxWidth: "1280px", padding: "0px 32px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="layout">
                <div className="right-content">
                  <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                  />
                </div>
                <div className="left-content">
                  <div className="d-inline-flex align-items-center">
                    <div className="bg-label-circle"></div>
                    <div className="bg-label-heading">
                      <label>CTA Text</label>
                    </div>
                  </div>
                  <div className="content">
                    <div className="Heading">
                      <h4>Precise whole genome embryo reports </h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Mitigate more risks with the world’s most advanced whole
                        genome screening for embryos
                      </p>
                    </div>
                    <div
                      className="gap-md d-flex align-items-center"
                      style={{ marginTop: "40px" }}
                    >
                      <Link to="/login" className="btn btn-outline">
                        Log in
                      </Link>
                      <Link to="/signup" className="btn btn-back">
                        Get Access
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CTA;
