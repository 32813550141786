import Hero from "./section/Hero";
import HowKitWorks from "./section/HowKitWorks";
import RevealGenetics from "./section/RevealGenetics";
import FirstKind from "./section/FirstKind";
import Testimonial from "../../common/Testimonial";
import ExpertGuide from "./section/ExpertGuide";
import Team from "../../common/Team";
import Blogs from "./section/Blogs";
import FAQ from "../../common/FAQ";
import CTA from "../../common/CTA";
import PageLayout from "../../layout/PageLayout";
import Header from "../../template/Header";
const Product = () => {
  return (
    <>
        <Header />
      <Hero />
      <HowKitWorks />
      <RevealGenetics />
      <FirstKind />
      <Testimonial />
      <ExpertGuide />
      <Team />
      <Blogs />
      <FAQ />
      <CTA />
      <PageLayout/>
      {/*       
      
      
       */}
    </>
  );
};

export default Product;
