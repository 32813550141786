import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const SignUp = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };
  return (
    <>
      <div className="auth-signup">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="form">
              <div className="auth-logo">
                <img src="/img/svg/logo.svg"></img>
                <h4>Get access </h4>
                <p>Welcome back! Please enter your details.</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="w-100">
                    <label className="form-label">Name*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="w-100">
                    <label className="form-label">Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="w-100 select-phone">
                    <label className="form-label">Phone</label>
                    <div className="form-group-phone">
                      <select className="form-control">
                        <option selected>US</option>
                      </select>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="+1 (555) 000-0000"
                      />
                    </div>
                  </div>
                  <div className="w-100 phone-text">
                    <p>
                      If you provide your phone number, we will call you to set
                      up a consultation
                    </p>
                  </div>
                  <button type="submit" className="btn send-mess">
                    Get access
                  </button>
                  <p className="login-btn">
                    Already have an account? <Link to="/login">Log in</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="content">
              <Slider {...settings}>
                <div className="card">
                  <div className="card-content">
                    <ul className="star list-inline">
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                    </ul>
                    <div className="comment">
                      <p>
                        “This is the way to reduce disease and suffering in
                        kids, and is the best thing you can do for your child
                        and yourself.
                        <br />
                        <br /> The fact that we have screened for so much gives
                        us peace of mind. ”
                      </p>
                    </div>
                    <div>
                      <div className="user-img">
                        <img src="/img/png/lori.png" />
                      </div>

                      <div className="user-detail">
                        <h4>Lori Bryson</h4>
                        <p>Product Designer, Sisyphus</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-content">
                    <ul className="star list-inline">
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                      <li>
                        <img src="/img/svg/Star.svg" />
                      </li>
                    </ul>
                    <div className="comment">
                      <p>
                        “This is the way to reduce disease and suffering in
                        kids, and is the best thing you can do for your child
                        and yourself.
                        <br />
                        <br /> The fact that we have screened for so much gives
                        us peace of mind. ”
                      </p>
                    </div>
                    <div>
                      <div className="user-img">
                        <img src="/img/png/lori.png" />
                      </div>

                      <div className="user-detail">
                        <h4>Lori Bryson</h4>
                        <p>Product Designer, Sisyphus</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
              <div className="content-bottom">
                <div>
                  <p>© Untitled UI 2077</p>
                </div>
                <div>
                  <p>
                    <img src="/img/svg/mail.svg" />
                    help@untitledui.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
