import Hero from "./section/Hero";
import GeneOverview from "./section/GeneOverview";
import PGT from "./section/PGT";
import PricingTable from "../../common/PricingTable";
import Team from "../../common/Team";
import Investors from "../../common/Investors";
import FAQ from "../../common/FAQ";
import CTA from "../../common/CTA";
import Header from "../../template/Header";
import PageLayout from "../../layout/PageLayout";
const Clinic = () => {
  return (
    <>
      <Header />
      <Hero />
      <GeneOverview />
      <PGT />
      <PricingTable />
      <Team />
      <Investors />
      <FAQ />
      <CTA />
      <PageLayout />
    </>
  );
};

export default Clinic;
