import React from "react";
import { useInView } from "react-intersection-observer";
const RevealGenetics = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  const [ref4, inView4] = useInView({
    threshold: 0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  return (
    <>
      <section className="RevealGenetics">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="d-inline-flex align-items-center">
                <div className="bg-label-circle"></div>
                <div className="bg-label-heading">
                  <label>Make an informed decision</label>
                </div>
              </div>
              <div className="content">
                <div className="Heading">
                  <h4>
                    Reveal how your genetics influence your child's health.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1280px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="list-coloumn">
                <div className="list">
                  <div className="card">
                    <div
                      ref={ref}
                      className={`img border-anim ${
                        inView ? "border-anim-add" : ""
                      }`}
                    >
                      <div className="outline-bottom-left"></div>
                      <div className="outline-left-top"></div>
                      <img src="/img/png/brainhealth.png" alt=""/>
                    </div>
                    <div className="card-content">
                      <h4>Brain Health</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                        litora torquent per conubia nostra, per inceptos
                        himenaeos.
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      ref={ref2}
                      className={`img border-anim ${
                        inView2 ? "border-anim-add" : ""
                      }`}
                    >
                      <div className="outline-bottom-left"></div>
                      <div className="outline-left-top"></div>
                      <img src="/img/png/hearthealth.png" alt=""/>
                    </div>
                    <div className="card-content">
                      <h4>Hearth Health</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                        litora torquent per conubia nostra, per inceptos
                        himenaeos.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="list">
                  <div className="card">
                    <div
                      ref={ref3}
                      className={`img border-anim ${
                        inView3 ? "border-anim-add" : ""
                      }`}
                    >
                      <div className="outline-bottom-left"></div>
                      <div className="outline-left-top"></div>
                      <img src="/img/png/cancers.png" alt=""/>
                    </div>
                    <div className="card-content">
                      <h4>Cancers</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                        litora torquent per conubia nostra, per inceptos
                        himenaeos.
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      ref={ref4}
                      className={`img border-anim ${
                        inView4 ? "border-anim-add" : ""
                      }`}
                    >
                      <div className="outline-bottom-left"></div>
                      <div className="outline-left-top"></div>
                      <img src="/img/png/overallhealth.png" alt=""/>
                    </div>
                    <div className="card-content">
                      <h4>Overall health</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                        litora torquent per conubia nostra, per inceptos
                        himenaeos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RevealGenetics;
