const ExpertGuide = () => {
  return (
    <>
      <section className="ExpertGuide">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle"></div>
                  <div className="bg-label-heading">
                    <label>How it works</label>
                  </div>
                </div>
                <div className="content">
                  <div className="Heading">
                    <h4>Simple, expert-guided, at-home</h4>
                  </div>
                  <div className="Desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                      litora torquent per conubia nostra, per inceptos
                      himenaeos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container show-web hide-mobile"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="guide-content">
                <div className="left-content">
                  <div className="content-text">
                    <div className="img">
                      <img src="/img/png/guideiconone.png" />
                    </div>
                    <div className="Heading">
                      <h4>Lorem ipsum</h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Our kit comes with two saliva collection vials - one for
                        him and one for her.
                      </p>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                  </ul>
                </div>
                <div className="right-content">
                  <img src="/img/svg/Screen mockup.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container hide-web show-mobile"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="guide-content">
                <div className="right-content">
                  <img src="/img/svg/Screen mockup.svg" />
                </div>
                <div className="left-content">
                  <div className="content-text">
                    <div className="img">
                      <img src="/img/png/guideiconone.png" />
                    </div>
                    <div className="Heading">
                      <h4>Lorem ipsum</h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Our kit comes with two saliva collection vials - one for
                        him and one for her.
                      </p>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container show-web hide-mobile"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="guide-content">
                <div className="right-content">
                  <img src="/img/png/imageblank.png" />
                </div>

                <div className="left-content">
                  <div className="content-text">
                    <div className="img">
                      <img src="/img/png/guideicontwo.png" />
                    </div>
                    <div className="Heading">
                      <h4>Get your reports</h4>
                    </div>
                    <div className="Desc">
                      <p>You'll get 3 reports:</p>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container hide-web show-mobile"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="guide-content">
                <div className="right-content">
                  <img src="/img/png/imageblank.png" />
                </div>

                <div className="left-content">
                  <div className="content-text">
                    <div className="img">
                      <img src="/img/png/guideicontwo.png" />
                    </div>
                    <div className="Heading">
                      <h4>Get your reports</h4>
                    </div>
                    <div className="Desc">
                      <p>You'll get 3 reports:</p>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container show-web hide-mobile"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="guide-content">
                <div className="left-content">
                  <div className="content-text">
                    <div className="img">
                      <img src="/img/png/guideiconthree.png" />
                    </div>
                    <div className="Heading">
                      <h4>Consult with experts</h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Get personalized expert consultation and your questions
                        answered.
                      </p>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                  </ul>
                </div>
                <div className="right-content">
                  <img src="/img/png/imageblank.png" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container hide-web show-mobile"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="guide-content">
                <div className="right-content">
                  <img src="/img/png/imageblank.png" />
                </div>
                <div className="left-content">
                  <div className="content-text">
                    <div className="img">
                      <img src="/img/png/guideiconthree.png" />
                    </div>
                    <div className="Heading">
                      <h4>Consult with experts</h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Get personalized expert consultation and your questions
                        answered.
                      </p>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                    <li>
                      <span>
                        <img src="/img/png/checkicon.png" />
                      </span>
                      More explanation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExpertGuide;
