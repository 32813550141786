import Lottie from "lottie-react";
import React, { useState, useEffect, useRef } from "react";
import animationData from "./lottieone.json";
import { Link } from "react-router-dom";
const EstimateDisease = () => {
  // const [isPlaying, setIsPlaying] = useState(false);

  // const handleHover = () => {
  //   setIsPlaying(true);
  // };

  // const handleBlur = () => {
  //   setIsPlaying(false);
  // };

  const lottieRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const handleScroll = () => {
    const sectionElement = lottieRef.current;
    if (sectionElement && isInViewport(sectionElement)) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className="GetScreening sideWidth">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px", position: "relative" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="content">
                  <div className="text">
                    <div>
                      <h4>Get whole genome embryo screening</h4>
                    </div>
                    <div>
                      <p>Join over 4,000+ couples or something.</p>
                    </div>
                  </div>
                  <div className="Buttons">
                    <div>
                      <button
                        style={{ borderRadius: "8px" }}
                        className="btn btn-outline-white"
                      >
                        Learn more
                      </button>
                    </div>
                    <div>
                      <Link
                        style={{ borderRadius: "8px" }}
                        to="/signup"
                        className="btn btn-back"
                      >
                        Get Access
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="EstimateDisease paddingTop">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="frame">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle"></div>
                  <div className="bg-label-heading">
                    <label>Genetic predisposition screening</label>
                  </div>
                </div>
                <div className="grid">
                  <div className="left-content">
                    <div className="content">
                      <div className="Heading">
                        <h4>
                          Estimate how much chronic disease risk can be
                          mitigated
                        </h4>
                      </div>
                      <div className="Desc">
                        <p>
                          Find the embryo at lowest risk for a disease that runs
                          in your family
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    <div>
                      <div className="list">
                        <div className="list-text">
                          <h4>Brain health</h4>
                          <p>
                            Alzheimer’s disease <br /> Bipolar disorder
                            Schizophrenia
                          </p>
                        </div>
                        <div className="list-text">
                          <h4>Heart health</h4>
                          <p>
                            Artial Fibrillation <br /> Coronary artery disease
                          </p>
                        </div>
                      </div>
                      <div className="list">
                        <div className="list-text">
                          <h4>Cancers</h4>
                          <p className="pb-0">
                            Breast cancer <br /> Prostate cancer
                          </p>
                        </div>
                        <div className="list-text">
                          <h4>General health</h4>
                          <p className="pb-0">
                            Celiac disease <br /> Inflammatory bowel disease
                            Type I diabetes <br /> Type II diabetes
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="bg-gradiant-line"></div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
          ref={lottieRef}
        >
          <div className="row">
            <div className="col-12">
              {/* <img src="/img/png/backanim.png" /> */}
              <Lottie
                animationData={animationData}
                loop={isPlaying}
                autoplay={!isPlaying}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EstimateDisease;
