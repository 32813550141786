import Hero from "./section/Hero";
import ScienceTeam from "./section/ScienceTeam";
import ReadingEmbryo from "./section/ReadingEmbryo";
import SingleScreening from "./section/SingleScreening";
import GeneticScreening from "./section/GeneticScreening";
import PricingTable from "../../common/PricingTable";
import FAQ from "../../common/FAQ";
import CTA from "../../common/CTA";
import Header from "../../template/Header";
import PageLayout from "../../layout/PageLayout";
const Science = () => {
  return (
    <>
      <Header />
      <Hero />
      <ScienceTeam />
      <ReadingEmbryo />
      <SingleScreening />
      <GeneticScreening />
      <PricingTable />
      <FAQ />
      <CTA />
      <PageLayout />
      {/*
       */}
    </>
  );
};

export default Science;
