import React, { useEffect, useState } from "react";
import Header from "../../../../template/Header";
import CTA from "../../../../common/CTA";
import PageLayout from "../../../../layout/PageLayout";
import { GetArticleDetailData } from "../../../../../Api/Article/Article";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { Config } from "../../../../../Config/Config";
const ArticlesDetail = () => {
  const [getBlogsDetail, setGetBlogsDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let slug = useParams();

  useEffect(() => {
    getBlogListingDetail(slug.id);
  }, []);

  const getBlogListingDetail = async (slug) => {
    try {
      const response = await GetArticleDetailData(slug);
      const data = response.data?.data;
      setGetBlogsDetail(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderContent = (content) => {
    switch (content.type) {
      case "text":
        return content?.bold ? (
          <b>{content.text}</b>
        ) : content?.italic ? (
          <i>{content.text}</i>
        ) : (
          content.text
        );
      case "link":
        return <a href={content.url}>{content.children[0].text}</a>;
      case "bold":
        return <strong>{content.text}</strong>;
      default:
        return null;
    }
  };

  const renderTextWithLineBreaks = (content) => {
    return content.text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {content?.bold ? <b>{line}</b> : content?.italic ? <i>{line}</i> : line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <Header />

      <section className="Hero-9">
        <div
          className="container"
          style={{ maxWidth: "816px", padding: "0px" }}
        >
          {!isLoading && (
            <div className="row">
              <div className="col-12">
                <div className="Banner">
                  <Link to="/resources" class="Frame-Route">
                    <div>
                      <img src="/img/svg/logo.svg" alt="..." />
                    </div>
                    <div>
                      <h4>/Resources</h4>
                    </div>
                  </Link>
                  <h4>{getBlogsDetail?.attributes?.title}</h4>
                  <img
                    className="w-100"
                    src={`${Config.baseuUrl}${getBlogsDetail?.attributes?.background_image?.data?.attributes?.url}`}
                    alt="background_image"
                  />
                </div>

                <div className="Content">
                  <p>{getBlogsDetail?.attributes?.description}</p>
                  <div>
                    {getBlogsDetail?.attributes?.blog_content?.map(
                      (content, index) => {
                        switch (content.type) {
                          case "paragraph":
                            return (
                              <p key={index}>
                                {content.children.map((child, childIndex) => (
                                  <React.Fragment key={childIndex}>
                                    {child.type === "text"
                                      ? renderTextWithLineBreaks(child)
                                      : renderContent(child)}
                                  </React.Fragment>
                                ))}
                              </p>
                            );
                          case "list":
                            return (
                              <ul key={index}>
                                {content.children.map((item, itemIndex) => (
                                  <li key={itemIndex}>
                                    {item.children
                                      .map((child) => child.text)
                                      .join("")}
                                  </li>
                                ))}
                              </ul>
                            );
                          default:
                            return null;
                        }
                      }
                    )}
                  </div>
                  <label>
                    {
                      getBlogsDetail?.attributes?.categories?.data?.[0]
                        ?.attributes?.name
                    }
                  </label>
                  <div class="d-flex">
                    <div class="avatar">
                      <img
                        src={`${Config.baseuUrl}${
                          getBlogsDetail?.attributes?.creator?.data?.attributes
                            ?.creator_image?.data?.attributes?.url ||
                          `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`
                        }`}
                        alt="creator_image"
                      />
                    </div>
                    <div class="text">
                      <h4>
                        {
                          getBlogsDetail?.attributes?.creator?.data?.attributes
                            ?.creator_name
                        }
                      </h4>
                      <p>
                        {" "}
                        {moment(getBlogsDetail?.attributes?.publishedAt).format(
                          "DD MMM YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <CTA />
      <PageLayout />
    </>
  );
};

export default ArticlesDetail;
