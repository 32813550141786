import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <>
      <section className="Hero-2">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Hero-Content gap-24">
                <div className="Frame-Route">
                  <div>
                    <img src="/img/svg/logo.svg" />
                  </div>
                  <div>
                    <h4>/Embryo Kit</h4>
                  </div>
                </div>
                <div className="Content">
                  <div>
                    <h4>Advanced preconception screening</h4>
                  </div>
                  <div>
                    <p>
                      Safely and naturally, protect your baby from diseases that
                      run in your family.
                    </p>
                  </div>
                </div>
                <div className="Frame-Input">
                  <Link to="/signup" className="btn btn-back">
                    Get Access
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
