import { Link } from "react-router-dom";
const ResetSuccess = () => {
  return (
    <>
      <div className="forgot ResetSuccess">
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="auth-logo">
                <img src="/img/svg/check-circle1.svg"></img>
                <h4>Password reset</h4>
                <p>Your password has been successfully reset.</p>
                <p>Click below to log in magically.</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <button type="submit" className="btn m-0 send-mess">
                    Continue
                  </button>

                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontFamily: "TASA Orbiter Text Regular",
                      fontSize: "14px",
                    }}
                    to="/login"
                    className="login-btn"
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src="/img/svg/arrow-left.svg"
                    />
                    Back to log in
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetSuccess;
