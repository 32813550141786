import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { GetArticleTitlesData } from "../../../../Api/Article/Article";
const SingleScreening = () => {
  const [getArticles, setGetArticles] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
  });

  useEffect(() => {
    GetArticleListing();
  }, []);

  const GetArticleListing = async () => {
    try {
      const response = await GetArticleTitlesData(1, 10);
      const data = response.data?.data;
      setGetArticles(data);
    } catch (error) {}
  };

  return (
    <>
      <section
        className="ScreenDisease SingleScreening"
        style={{ paddingTop: "0px" }}
      >
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle"></div>
                  <div className="bg-label-heading">
                    <label>Analyze more data + mitigate more risks</label>
                  </div>
                </div>
                <div className="content">
                  <div className="Heading">
                    <h4>Title goes here</h4>
                  </div>
                  <div className="Desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Frame">
                <div className="left-content">
                  <div
                    className={`card border-anim ${
                      inView ? "border-anim-add" : ""
                    }`}
                  >
                    <div className="outline-bottom-left"></div>
                    <div className="outline-left-top"></div>
                    <div className="content" ref={ref}>
                      <div>
                        <div className="d-inline-flex align-items-center">
                          <div className="bg-label-circle"></div>
                          <div className="bg-label-heading">
                            <label>Clinically validated</label>
                          </div>
                        </div>
                        <div className="Heading">
                          <h4 className="">
                            Monogenic (single-gene) screening
                          </h4>
                        </div>
                      </div>
                      <div className="text">
                        <div className="heading">
                          <h4>Orchid reads 99.6%* of an embryo’s genome</h4>
                        </div>
                        <div className="desc">
                          <p>
                            Orchid can screen for hundreds of monogenic
                            disorders previously impossible to detect in
                            embryos.‍
                          </p>
                          <p>
                            Geneticists have done extraordinary work to identify
                            monogenic causes for severe conditions. They
                            analyzed the DNA of thousands of individuals
                            affected with various conditions like
                            neurodevelopmental disorders, hereditary cancers,
                            and birth defects to identify the genetic typo, or
                            variation, that led to their condition.‍
                          </p>
                          <p>
                            Not every disease has a genetic cause but
                            geneticists have compiled hundreds of genes that are
                            known to cause genetic disease and this is what
                            informs Orchid’s screening. ‍
                          </p>
                          <p>
                            This table show the types of conditions Orchid
                            screens for and the estimated percentage of cases
                            that can be attributed to a single genetic cause
                            based on scientific literature.
                          </p>
                        </div>
                      </div>
                      <div className="links">
                        <h4>Read more here</h4>

                        {getArticles?.map((item, index) => (
                          <Link
                            to={`/blog-detail/${item.id}`}
                            key={getArticles.id}
                          >
                            {item?.attributes?.title.slice(0, 50)}
                            {item?.attributes?.title?.length > 50 ? "..." : ""}
                            <img alt='img' src="/img/svg/arrow-up-right.svg"/>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-content">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="w-100 table-demand">
                        <thead>
                          <tr>
                            <td></td>

                            <td>
                              <h5>Text?</h5>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4>
                                NEURODEVEOPMENTAL DISORDERS
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Intellectual Disability
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Autism Spectrum Disorders
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Epilepsy
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>HEREDITARY CANCER</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">
                                <a href="#!">10-40%</a>
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Adrenocortical carcinoma (pediatric)
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Retinoblastoma (pediatric)
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Ovarian cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Breast cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Pancreatic cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Central Nervous System tumor (pediatric)
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Wilms’ tumor (pediatric)
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Prostate cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Colorectal cancer (overall)
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Brain
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Retinoblastoma (pediatric)
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Ovarian cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Breast cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Pancreatic cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">30%</h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleScreening;
