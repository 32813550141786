import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <>
      <section className="Hero-6">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="Hero-Content gap-24">
                  <div className="Frame-Route">
                    <div>
                      <img src="/img/svg/logo.svg" />
                    </div>
                    <div>
                      <h4>/Contact</h4>
                    </div>
                  </div>
                  <div className="Content">
                    <div>
                      <h4>We'd love to hear from you.</h4>
                    </div>
                    <div>
                      <p>Question not answered? We're here to help.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="Name">
                    <div>
                      <label className="form-label">First name</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="First name"
                      />
                    </div>
                    <div>
                      <label className="form-label">Last name</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="you@company.com"
                    />
                  </div>
                  <div className="w-100 select-phone">
                    <label className="form-label">Phone</label>
                    <div className="form-group-phone">
                      <select className="form-control">
                        <option selected>US</option>
                      </select>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="you@company.com"
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <label className="form-label">Email</label>
                    <textarea
                      className="form-control"
                      placeholder="Leave us a message..."
                    ></textarea>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      You agree to our friendly{" "}
                      <Link to="/privacy-policy">privacy policy</Link>.
                    </label>
                  </div>
                  <button type="submit" className="btn send-mess">
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
