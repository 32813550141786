import React from "react";
import { useInView } from "react-intersection-observer";
const ScreenDisease = () => {
  const [ref, inView] = useInView({
    threshold:0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });

  const [ref2, inView2] = useInView({
    threshold:0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  return (
    <>
      <section className="ScreenDisease paddingBottom">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card-full">
                <div className="grid">
                  <div className="left-content">
                    <div className="d-inline-flex align-items-center">
                      <div className="bg-label-circle"></div>
                      <div className="bg-label-heading">
                        <label>Mono/Poly-genic solutions</label>
                      </div>
                    </div>
                    <div className="Heading">
                      <h4>What we screen for</h4>
                    </div>
                  </div>
                  <div className="right-content">
                    <p>
                      Use whole genome data to identify monogenic and polygenic
                      diseases. Alternatives miss detecting risks because they
                      do not sequence the whole genome.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Frame">
                <div className="left-content">
                  <div
                   ref={ref}
                    className={`card border-anim ${
                      inView ? "border-anim-add" : ""
                    }`}
                    style={{ height: "auto" }}
                  >
                    <div className="outline-bottom-left"></div>
                    <div className="outline-left-top"></div>
                    <div className="vector">
                      <img src="/img/svg/mind.svg" alt="mind"/>
                    </div>
                    <div className="content">
                      <div className="text">
                        <div className="heading">
                          <h4>Neurodevelopmental disorders</h4>
                        </div>
                        <div className="desc">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc ac ornare tortor. Class aptent taciti
                            sociosqu ad litora torquent per conubia nostra, per
                            inceptos himenaeos.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="list">
                          <div className="list-text">
                            <h4>Intellectual disability</h4>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.{" "}
                            </p>
                          </div>
                          <div className="list-text">
                            <h4>Autism spectrum disorders</h4>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="list">
                          <div className="list-text">
                            <h4>Global developmental delay</h4>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </div>
                          <div className="list-text">
                            <h4>Epilepsy</h4>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-content">
                    <h4>Pediatric and adult-onset cancers</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                      litora torquent per conubia nostra, per inceptos
                      himenaeos.
                    </p>
                  </div>
                  <div className="text-content">
                    <h4>Birth defects</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                      litora torquent per conubia nostra, per inceptos
                      himenaeos.
                    </p>
                  </div>
                </div>
                <div className="right-content">
                  <div
                    ref={ref2}
                    className={`card border-anim ${
                      inView2 ? "border-anim-add" : ""
                    }`}
                  >
                    <div className="outline-bottom-left"></div>
                    <div className="outline-left-top"></div>
                    <img src="/img/png/iPhone.png" alt="iphone"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScreenDisease;
