import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const Career = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  // const [reft, inViewt] = useInView({
  //   triggerOnce: false,
  //   threshold: 0.5,
  // });

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="Career">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="frame">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle" />
                  <div className="bg-label-heading">
                    <label>Professional Counseling</label>
                  </div>
                </div>
                <div className="grid">
                  <div className="left-content">
                    <div className="content">
                      <div className="Heading">
                        <h4>
                          Enabling patients to make informed choices regarding
                          healthcare.
                        </h4>
                      </div>
                      <div className="Desc">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nunc ac ornare tortor. Class aptent taciti
                          sociosqu ad litora torquent per conubia nostra, per
                          inceptos himenaeos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    <div>
                      <div className="list">
                        <div className="list-text">
                          <h4>
                            <img src="/img/svg/security.svg" alt="" />
                            Carrier screening
                          </h4>
                          <p>
                            Take the reins on which risk profiles you want to
                            support.
                          </p>
                        </div>
                        <div className="list-text">
                          <h4>
                            <img src="/img/svg/data.svg" alt="" />
                            Cancer screening
                          </h4>
                          <p>
                            Identity requirements that fit your customers and
                            business.
                          </p>
                        </div>
                      </div>
                      <div className="list">
                        <div className="list-text">
                          <h4>
                            <img src="/img/svg/facilitated.svg" alt="" />
                            Prenatal screening
                          </h4>
                          <p className="pb-0">
                            Run your own transaction <br /> monitoring in-house.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12" ref={ref}>
              <Slider {...settings}>
                <div
                  className={`card border-anim ${
                    inView ? "border-anim-add" : ""
                  }`}
                >
                  <div className="outline-bottom-left"></div>
                  <div className="outline-left-top"></div>
                  <div className="img">
                    <img alt="img" src="/img/png/partner.png" />
                  </div>
                  <div className="card-content">
                    <h4>For business partners</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                      litora torquent per conubia nostra, per inceptos
                      himenaeos.
                    </p>
                    <div className="Btns">
                      <div>
                        <button className="btn btn-outline-white">
                          Learn more
                        </button>
                      </div>
                      <div>
                        <Link to="/signup" className="btn btn-back-blue">
                          Get Access
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`card border-anim ${
                    inView ? "border-anim-add" : ""
                  }`}
                >
                  <div className="outline-bottom-left"></div>
                  <div className="outline-left-top"></div>
                  <div className="img">
                    <img src="/img/png/patient.png" alt="" />
                  </div>
                  <div className="card-content">
                    <h4>For patients</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                      litora torquent per conubia nostra, per inceptos
                      himenaeos.
                    </p>
                    <div className="Btns">
                      <div>
                        <button className="btn btn-outline-white">
                          Learn more
                        </button>
                      </div>
                      <div>
                        <Link to="/signup" className="btn btn-back-blue">
                          Get Access
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
