import React from "react";
import { useInView } from "react-intersection-observer";
const GeneOverview = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
    threshold:0.5
  });
  return (
    <>
      <section className="GeneOverview">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="d-inline-flex align-items-center">
                <div className="bg-label-circle"></div>
                <div className="bg-label-heading">
                  <label>Customized compliance</label>
                </div>
              </div>
              <div className="content">
                <div className="Heading">
                  <h4>Geneblock’s overview</h4>
                </div>
                <div className="Desc">
                  <p>
                    Orchid is a cutting-edge whole genome embryo screening that
                    combines Preimplantation Genetic Testing for Aneuploidy
                    (PGT-A), Preimplantation Genetic Testing for
                    Monogenic/Single Gene Defects (PGT-M), and screens for
                    hundreds of genes linked to severe monogenic diseases.‍
                  </p>
                  <br />
                  <p>
                    Orchid helps families maximize their chance for a successful
                    pregnancy and healthy baby by screening embryos using whole
                    genome sequencing.
                  </p>
                  <br />
                  <p>
                    Orchid obtains 100x more data, and reads &gt; 99%* of an
                    embryo’s genome (compared to &lt; 1% that our competitors
                    read). This is all done using the same 5-6 cell
                    trophectoderm biopsy. With this advanced data, Orchid can
                    detect conditions that other tests miss.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="frame">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle" />
                  <div className="bg-label-heading">
                    <label>Customized compliance</label>
                  </div>
                </div>
                <div className="grid">
                  <div className="left-content">
                    <div className="colab-content">
                      <div className="Heading">
                        <h4>Collaborating with Geneblock</h4>
                      </div>
                      <div className="Desc">
                        <p>
                          Orchid's collaboration process is designed to be
                          seamless and straightforward.
                        </p>
                        <p>
                          After an initial orientation, clinicians can easily
                          order tests with Orchid handling all shipping
                          logistics. Using the same 5-6 cell trophectoderm
                          biopsy, Orchid provides comprehensive results and
                          individualized reporting and consultations.
                        </p>
                      </div>
                      <div
                        ref={ref}
                        className={`Banner border-anim ${
                          inView ? "border-anim-add" : ""
                        }`}
                      >
                        <div className="outline-bottom-left"></div>
                        <div className="outline-left-top"></div>
                        <img alt='img' src="/img/svg/Frame 232.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    <div className="list">
                      <div className="avatar">
                        <img alt='img' src="/img/svg/messagechat.svg" />
                      </div>
                      <div className="text">
                        <h4>Contact Orchid for an orientation</h4>
                        <p>
                          Whether you have a team of 2 or 200, our shared team
                          inboxes keep everyone on the same page and in the
                          loop.
                        </p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="avatar">
                        <img alt='img' src="/img/svg/zap.svg" />
                      </div>
                      <div className="text">
                        <h4>Quick and easy ordering</h4>
                        <p>
                          An all-in-one customer service platform that helps you
                          balance everything your customers need to be happy.
                        </p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="avatar">
                        <img alt='img' src="/img/svg/stars.svg" />
                      </div>
                      <div className="text">
                        <h4>Same biopsy, superior results</h4>
                        <p>
                          Get 100x more data and more comprehensive results with
                          the same 5-6 cell trophectoderm biopsy
                        </p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="avatar">
                        <img alt='img' src="/img/svg/filesearch.svg" />
                      </div>
                      <div className="text">
                        <h4>Individualized reporting and consults</h4>
                        <p>
                          Your patients will receive 1:1 consult with a board
                          certified genetic counselor.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeneOverview;
