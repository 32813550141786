import invoke from "../../utils/invoke";
import { Config } from "../../Config/Config";

export const GetArticleData = (data, pageSize = 10) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    // route: `blogs/?page=${data}`,
    route: `api/genome-blogs/?sort=createdAt:DESC&pagination[page]=${data}&pagination[pageSize]=${pageSize}&populate[0]=creator&populate[2]=creator.creator_image.media&populate[3]=categories&populate[4]=display_image&populate[5]=background_image`,
    data: data,
  });
};

export const GetArticleTitlesData = (data, pageSize = 10) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    // route: `blogs/?page=${data}`,
    route: `api/genome-blogs/?sort=createdAt:DESC&pagination[page]=${data}&pagination[pageSize]=${pageSize}&populate[0]=creator&populate[3]=categories`,
    data: data,
  });
};

export const GetTopArticleData = () => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    // route: `blogs/?page=${data}`,
    route: `api/genome-blogs/?sort=createdAt:DESC&pagination[page]=1&pagination[pageSize]=1&populate[0]=creator&populate[2]=creator.creator_image.media&populate[3]=categories&populate[4]=display_image&populate[5]=background_image`,
  });
};

export const GetArticleDetailData = (slug) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    // route: `blogsdetail/?id=${slug}`,
    route: `api/genome-blogs/${slug}?populate[0]=creator&populate[2]=creator.creator_image.media&populate[3]=categories&populate[4]=display_image&populate[5]=background_image`,
  });
};

// https://blog.minikubes.com/blogs?category=Case Reports
