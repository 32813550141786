import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
const Testimonial = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // variableWidth: true,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}>{dots}</ul>
        <span>/ 0X</span>
      </div>
    ),
    customPaging: (i) => <h4>{i + 1}</h4>,
  };
  return (
    <>
      <section className="Testimonial mb-5">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="d-inline-flex align-items-center">
                <div className="bg-label-circle"></div>
                <div className="bg-label-heading">
                  <label>Make an informed decision</label>
                </div>
              </div>
              <div className="Heading">
                <h4>What people are saying</h4>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                <div className="card">
                  <div className="user-img">
                    <img src="/img/svg/user.svg" />
                  </div>
                  <div className="comment">
                    <p>
                      “This is the way to reduce disease and suffering in kids,
                      and is the best thing you can do for your child and
                      yourself.
                      <br />
                      <br /> The fact that we have screened for so much gives us
                      peace of mind. ”
                    </p>
                  </div>
                  <div className="user-detail">
                    <h4>Rohan & Jen</h4>
                    <p>Orchid customers</p>
                  </div>
                </div>
                <div className="card">
                  <div className="user-img">
                    <img src="/img/svg/user2.png" />
                  </div>
                  <div className="comment">
                    <p>
                      “We screened our embryos because were at risk for a
                      hereditary cancer. Orchid found something more - we were
                      carriers for the same condition.
                      <br />
                      <br /> Now, our kids are more likely to be healthy.”
                    </p>
                  </div>
                  <div className="user-detail">
                    <h4>Andy & Ella</h4>
                    <p>Orchid customers</p>
                  </div>
                </div>
                <div className="card">
                  <div className="user-img">
                    <img src="/img/svg/user3.png" />
                  </div>
                  <div className="comment">
                    <p>
                      “We deeply believe in leveraging technology to better our
                      lives. Orchid's groundbreaking technology was a
                      game-changer for us.
                      <br />
                      <br /> It provided invaluable insights as we embarked on
                      the path to parenthood.”
                    </p>
                  </div>
                  <div className="user-detail">
                    <h4>Rohan & Jen</h4>
                    <p>Orchid customers</p>
                  </div>
                </div>{" "}
                <div className="card">
                  <div className="user-img">
                    <img src="/img/svg/user.svg" />
                  </div>
                  <div className="comment">
                    <p>
                      “This is the way to reduce disease and suffering in kids,
                      and is the best thing you can do for your child and
                      yourself.
                      <br />
                      <br /> The fact that we have screened for so much gives us
                      peace of mind. ”
                    </p>
                  </div>
                  <div className="user-detail">
                    <h4>Rohan & Jen</h4>
                    <p>Orchid customers</p>
                  </div>
                </div>
                <div className="card">
                  <div className="user-img">
                    <img src="/img/svg/user2.png" />
                  </div>
                  <div className="comment">
                    <p>
                      “We screened our embryos because were at risk for a
                      hereditary cancer. Orchid found something more - we were
                      carriers for the same condition.
                      <br />
                      <br /> Now, our kids are more likely to be healthy.”
                    </p>
                  </div>
                  <div className="user-detail">
                    <h4>Andy & Ella</h4>
                    <p>Orchid customers</p>
                  </div>
                </div>
                <div className="card">
                  <div className="user-img">
                    <img src="/img/svg/user3.png" />
                  </div>
                  <div className="comment">
                    <p>
                      “We deeply believe in leveraging technology to better our
                      lives. Orchid's groundbreaking technology was a
                      game-changer for us.
                      <br />
                      <br /> It provided invaluable insights as we embarked on
                      the path to parenthood.”
                    </p>
                  </div>
                  <div className="user-detail">
                    <h4>Rohan & Jen</h4>
                    <p>Orchid customers</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
