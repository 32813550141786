import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <>
      <section className="Hero-8">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="Hero-Content gap-24">
                  <div className="Frame-Route">
                    <div>
                      <img src="/img/svg/logo.svg" />
                    </div>
                    <div>
                      <h4>/Careers</h4>
                    </div>
                  </div>
                  <div className="Content">
                    <div>
                      <h4>We’re looking for talented people</h4>
                    </div>
                    <div>
                      <p>
                        We are growing fast, and we are always looking for
                        passionate, dynamic, and talented individuals to join
                        our distributed team all around the world.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Banner" style={{width: '100%'}}>
                <img src="/img/png/doctor.png" style={{width: '100%'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
