import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { GetArticleTitlesData } from "../../../../Api/Article/Article";

const GeneticScreening = () => {
  const [getArticles, setGetArticles] = useState({});
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
  });

  useEffect(() => {
    GetArticleListing();
  }, []);

  const GetArticleListing = async () => {
    try {
      const response = await GetArticleTitlesData(1, 1);
      const data = response.data?.data;
      setGetArticles(data?.[0]);
    } catch (error) {}
  };
  return (
    <>
      <section className="GetScreening">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px", position: "relative" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="content">
                  <div className="text">
                    <div>
                      <h4>Get whole genome embryo screening</h4>
                    </div>
                    <div>
                      <p>Join over 4,000+ couples or something.</p>
                    </div>
                  </div>
                  <div className="Buttons">
                    <div>
                      <button
                        style={{ borderRadius: "8px" }}
                        className="btn btn-outline-white"
                      >
                        Learn more
                      </button>
                    </div>
                    <div>
                      <Link
                        style={{ borderRadius: "8px" }}
                        className="btn btn-back"
                        to="/signup"
                      >
                        Get Access
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ScreenDisease SingleScreening GeneticScreening paddingTop">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle"></div>
                  <div className="bg-label-heading">
                    <label>Analyze more data + mitigate more risks</label>
                  </div>
                </div>
                <div className="content">
                  <div className="Heading">
                    <h4>Title goes here</h4>
                  </div>
                  <div className="Desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Frame">
                <div className="left-content">
                  <div
                    ref={ref}
                    className={`card border-anim ${
                      inView ? "border-anim-add" : ""
                    }`}
                  >
                    <div className="outline-bottom-left"></div>
                    <div className="outline-left-top"></div>
                    <div className="content">
                      <div>
                        <div className="d-inline-flex align-items-center">
                          <div className="bg-label-circle"></div>
                          <div className="bg-label-heading">
                            <label>Clinically validated</label>
                          </div>
                        </div>
                        <div className="Heading">
                          <h4 className="">Genetic predisposition screening</h4>
                        </div>
                      </div>
                      <div className="text">
                        <div className="heading">
                          <h4>Orchid reads 99.6%* of an embryo’s genome</h4>
                        </div>
                        <div className="desc">
                          <p>
                            Many chronic diseases are influenced by the combined
                            impact of thousands of genetic variations, not just
                            one gene. Scientists have developed genetic risk
                            scores to quantify an individual’s overall genetic
                            predisposition to specific conditions.‍This tables
                            shows the diseases Orchid’s Reports cover. For
                            individuals at high genetic risk, it shows the
                            absolute lifetime risk which can be 2-30 times the
                            average risk of the disease.
                          </p>
                        </div>
                      </div>
                      <div className="links">
                        <h4>Read more here</h4>
                        {Object.keys(getArticles)?.length ? (
                          <Link
                            to={`/blog-detail/${getArticles.id}`}
                            key={getArticles.id}
                          >
                            {getArticles?.attributes?.title.slice(0, 50)}
                            {getArticles?.attributes?.title?.length > 50
                              ? "..."
                              : ""}
                            <img alt='img' src="/img/svg/arrow-up-right.svg"/>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-content">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="w-100 table-demand">
                        <thead>
                          <tr>
                            <td></td>

                            <td>
                              <h5>AVERAGE LIFETIME RISK</h5>
                            </td>
                            <td>
                              <h5>HIGH-RISK PREVALENCE</h5>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4>
                                Alzheimer's disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Atrial fibrillation
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Bipolar disorder
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Breast cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Coronary artery disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Celiac disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Inflammatory bowel disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Prostate cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Schizophrenia
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Alzheimer's disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Atrial fibrillation
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Bipolar disorder
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Breast cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Coronary artery disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Celiac disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Inflammatory bowel disease
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Prostate cancer
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>
                                Schizophrenia
                                <img alt='img' src="/img/svg/help-circle.svg" />
                              </h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                            <td>
                              <h4 className="justify-content-center">9.5%</h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeneticScreening;
