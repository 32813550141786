import CTA from "../common/CTA";
import PageLayout from "../layout/PageLayout";
import Header from "../template/Header";
const Privacy = () => {
  return (
    <>
    <Header/>
      <section className="Hero-7">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Hero-Content gap-24">
                <div className="Frame-Route">
                  <div>
                    <img src="/img/svg/logo.svg" />
                  </div>
                  <div>
                    <h4>/Privacy Policy</h4>
                  </div>
                </div>
                <div className="Content">
                  <div>
                    <h4>Privacy Policy</h4>
                  </div>
                  <div>
                    <p>
                      By accessing our website, you are agreeing to be bound by
                      these terms of service, all applicable laws and
                      regulations, and agree that you are responsible for
                      compliance with any applicable local laws.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Terms">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="content">
                <div className="Desc">
                  <p>
                    Mi tincidunt elit, id quisque ligula ac diam, amet. Vel
                    etiam suspendisse morbi eleifend faucibus eget vestibulum
                    felis. Dictum quis montes, sit sit. Tellus aliquam enim
                    urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi,
                    tellus tincidunt. At feugiat sapien varius id.
                  </p>
                  <br></br>
                  <p>
                    Eget quis mi enim, leo lacinia pharetra, semper. Eget in
                    volutpat mollis at volutpat lectus velit, sed auctor.
                    Porttitor fames arcu quis fusce augue enim. Quis at habitant
                    diam at. Suscipit tristique risus, at donec. In turpis vel
                    et quam imperdiet. Ipsum molestie aliquet sodales id est ac
                    volutpat.{" "}
                  </p>
                </div>
                <div className="Heading">
                  <h4>What information do we collect?</h4>
                </div>
                <div className="Desc">
                  <p>
                    Dolor enim eu tortor urna sed duis nulla. Aliquam
                    vestibulum, nulla odio nisl vitae. In aliquet pellentesque
                    aenean hac vestibulum turpis mi bibendum diam. Tempor
                    integer aliquam in vitae malesuada fringilla.
                  </p>
                  <br></br>
                  <p>
                    Elit nisi in eleifend sed nisi. Pulvinar at orci, proin
                    imperdiet commodo consectetur convallis risus. Sed
                    condimentum enim dignissim adipiscing faucibus consequat,
                    urna. Viverra purus et erat auctor aliquam. Risus, volutpat
                    vulputate posuere purus sit congue convallis aliquet. Arcu
                    id augue ut feugiat donec porttitor neque. Mauris, neque
                    ultricies eu vestibulum, bibendum quam lorem id. Dolor
                    lacus, eget nunc lectus in tellus, pharetra, porttitor.
                  </p>
                  <br></br>
                  <p>
                    Ipsum sit mattis nulla quam nulla. Gravida id gravida ac
                    enim mauris id. Non pellentesque congue eget consectetur
                    turpis. Sapien, dictum molestie sem tempor. Diam elit, orci,
                    tincidunt aenean tempus. Quis velit eget ut tortor tellus.
                    Sed vel, congue felis elit erat nam nibh orci.
                  </p>
                </div>
                <div className="Heading">
                  <h4>How do we use your information?</h4>
                </div>
                <div className="Desc">
                  <p>
                    Dolor enim eu tortor urna sed duis nulla. Aliquam
                    vestibulum, nulla odio nisl vitae. In aliquet pellentesque
                    aenean hac vestibulum turpis mi bibendum diam. Tempor
                    integer aliquam in vitae malesuada fringilla.
                  </p>
                  <br></br>
                  <p>
                    Elit nisi in eleifend sed nisi. Pulvinar at orci, proin
                    imperdiet commodo consectetur convallis risus. Sed
                    condimentum enim dignissim adipiscing faucibus consequat,
                    urna. Viverra purus et erat auctor aliquam. Risus, volutpat
                    vulputate posuere purus sit congue convallis aliquet. Arcu
                    id augue ut feugiat donec porttitor neque. Mauris, neque
                    ultricies eu vestibulum, bibendum quam lorem id. Dolor
                    lacus, eget nunc lectus in tellus, pharetra, porttitor.
                  </p>
                  <br></br>
                  <p>
                    Ipsum sit mattis nulla quam nulla. Gravida id gravida ac
                    enim mauris id. Non pellentesque congue eget consectetur
                    turpis. Sapien, dictum molestie sem tempor. Diam elit, orci,
                    tincidunt aenean tempus. Quis velit eget ut tortor tellus.
                    Sed vel, congue felis elit erat nam nibh orci.
                  </p>
                </div>
                <div className="Heading">
                  <h5>Do we use cookies and other tracking technologies?</h5>
                </div>
                <div className="Desc">
                  <p>
                    Pharetra morbi libero id aliquam elit massa integer tellus.
                    Quis felis aliquam ullamcorper porttitor. Pulvinar
                    ullamcorper sit dictumst ut eget a, elementum eu. Maecenas
                    est morbi mattis id in ac pellentesque ac.
                  </p>
                </div>
                <div className="Heading">
                  <h5>How long do we keep your information?</h5>
                </div>
                <div className="Desc">
                  <p>
                    Pharetra morbi libero id aliquam elit massa integer tellus.
                    Quis felis aliquam ullamcorper porttitor. Pulvinar
                    ullamcorper sit dictumst ut eget a, elementum eu. Maecenas
                    est morbi mattis id in ac pellentesque ac.
                  </p>
                </div>
                <div className="Heading">
                  <h5>How do we keep your information safe?</h5>
                </div>
                <div className="Desc">
                  <p>
                    Pharetra morbi libero id aliquam elit massa integer tellus.
                    Quis felis aliquam ullamcorper porttitor. Pulvinar
                    ullamcorper sit dictumst ut eget a, elementum eu. Maecenas
                    est morbi mattis id in ac pellentesque ac.
                  </p>
                </div>
                <div className="Heading">
                  <h4>How do we keep your information safe?</h4>
                </div>
                <div className="Desc">
                  <p>
                    Pharetra morbi libero id aliquam elit massa integer tellus.
                    Quis felis aliquam ullamcorper porttitor. Pulvinar
                    ullamcorper sit dictumst ut eget a, elementum eu. Maecenas
                    est morbi mattis id in ac pellentesque ac.
                  </p>
                </div>
                <div className="Heading">
                  <h4>How can you contact us about this policy?</h4>
                </div>
                <div className="Desc">
                  <p>
                    Sagittis et eu at elementum, quis in. Proin praesent
                    volutpat egestas sociis sit lorem nunc nunc sit. Eget diam
                    curabitur mi ac. Auctor rutrum lacus malesuada massa ornare
                    et. Vulputate consectetur ac ultrices at diam dui eget
                    fringilla tincidunt. Arcu sit dignissim massa erat cursus
                    vulputate gravida id. Sed quis auctor vulputate hac
                    elementum gravida cursus dis.
                  </p>
                  <br></br>
                  <p>1. Lectus id duis vitae porttitor enim gravida morbi.</p>
                  <p>
                    2. Eu turpis posuere semper feugiat volutpat elit, ultrices
                    suspendisse. Auctor vel in vitae placerat.
                  </p>
                  <p>
                    3. Suspendisse maecenas ac donec scelerisque diam sed est
                    duis purus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA />
      <PageLayout/>
    </>
  );
};

export default Privacy;
