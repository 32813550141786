import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Lottie from "lottie-react";

import animationData from "./200.json";

const FirstKind = () => {
  return (
    <>
      <section className="FirstKind">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="d-inline-flex align-items-center">
                <div className="bg-label-circle"></div>
                <div className="bg-label-heading">
                  <label>the orchid difference</label>
                </div>
              </div>
              <div className="content">
                <div className="Heading">
                  <h4>First of its kind. There's no test like ours.</h4>
                </div>
                <div className="Desc">
                  <p>
                    Get a deposit account, credit card, and spend management
                    software—in one refreshingly easy solution. No fees or
                    minimums.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="custom-tabs">
                <Tabs>
                  <TabPanel>
                    <div className="content-anim">
                      <div className="left-content">
                        <div className="Frame-Route">
                          <div>
                            <h4>/Embryo Kit</h4>
                          </div>
                        </div>
                        <div className="Heading">
                          <h2>9.B</h2>
                          <h3>Data points</h3>
                        </div>
                        <div className="Desc">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc ac ornare tortor.
                          </p>
                        </div>
                      </div>
                      <div className="right-content">
                        {/* <img src="/img/animation/layer.gif" /> */}
                        {/* <img
                          src="/img/animation/layerupdate.gif"
                          className="w-100"
                        /> */}
                        <Lottie
                          animationData={animationData}
                          loop={true}
                          autoplay={true}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="content-anim">
                      <div className="left-content">
                        <div className="Frame-Route">
                          <div>
                            <h4>/Embryo Kit</h4>
                          </div>
                        </div>
                        <div className="Heading">
                          <h2>9.B</h2>
                          <h3>Disease that matter</h3>
                        </div>
                        <div className="Desc">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc ac ornare tortor.
                          </p>
                        </div>
                      </div>
                      <div className="right-content">
                        {/* <img src="/img/animation/layer.gif" /> */}
                        <Lottie
                          animationData={animationData}
                          loop={true}
                          autoplay={true}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="content-anim">
                      <div className="left-content">
                        <div className="Frame-Route">
                          <div>
                            <h4>/Embryo Kit</h4>
                          </div>
                        </div>
                        <div className="Heading">
                          <h2>9.B</h2>
                          <h3>Mitigate risk</h3>
                        </div>
                        <div className="Desc">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc ac ornare tortor.
                          </p>
                        </div>
                      </div>
                      <div className="right-content">
                        {/* <img src="/img/animation/layer.gif" /> */}
                        <Lottie
                          animationData={animationData}
                          loop={true}
                          autoplay={true}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="content-anim">
                      <div className="left-content">
                        <div className="Frame-Route">
                          <div>
                            <h4>/Embryo Kit</h4>
                          </div>
                        </div>
                        <div className="Heading">
                          <h2>9.B</h2>
                          <h3>Physician approved</h3>
                        </div>
                        <div className="Desc">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc ac ornare tortor.
                          </p>
                        </div>
                      </div>
                      <div className="right-content">
                        {/* <img src="/img/animation/layer.gif" /> */}
                        <Lottie
                          animationData={animationData}
                          loop={true}
                          autoplay={true}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabList>
                    <Tab>
                      <h4>6 billion data points</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor.
                      </p>
                    </Tab>
                    <Tab>
                      <h4>Diseases that matter</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor.
                      </p>
                    </Tab>
                    <Tab>
                      <h4>Mitigate risk</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor.
                      </p>
                    </Tab>
                    <Tab>
                      <h4>Physician approved</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc ac ornare tortor.
                      </p>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstKind;
