import Header from "../template/Header";
import Footer from "../template/Footer";
const PageLayout = () => {
  return (
    <>
      <Footer />
    </>
  );
};

export default PageLayout;
