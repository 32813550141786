const Map = () => {
  return (
    <>
      <section className="Map">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="frame">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle" />
                  <div className="bg-label-heading">
                    <label>Contact us</label>
                  </div>
                </div>
                <div className="grid">
                  <div className="left-content">
                    <div className="colab-content">
                      <div className="Heading">
                        <h4>Chat to our friendly team</h4>
                      </div>
                      <div className="Desc">
                        <p>We’d love to hear from you! Please get in touch.</p>
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    <div className="list">
                      <div className="avatar">
                        <img src="/img/svg/marker.svg" />
                      </div>
                      <div className="text">
                        <h4>Melbourne</h4>
                        <p>100 Flinders Street, Melbourne VIC 3000 AU</p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="avatar">
                        <img src="/img/svg/marker.svg" />
                      </div>
                      <div className="text">
                        <h4>Sydney</h4>
                        <p>100 George Street, Sydney NSW 2000 AU</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="map-frame">
                <iframe
                  width="100%"
                  height="560"
                  loading="lazy"
                  allowfullscreen
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12345.678901234567!2d-71.0603164100647!3d42.358303327029845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370a22fd7f1bf%3A0x302d11d3d0f48f4d!2sExample%20Location!5e0!3m2!1sen!2sus!4v1643599999999!5m2!1sen!2sus"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Map;
