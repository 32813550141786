import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const PGT = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="GetScreening custom-styling">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px", position: "relative" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="content">
                  <div className="text">
                    <div>
                      <h4>Get whole genome embryo screening</h4>
                    </div>
                    <div>
                      <p>Join over 4,000+ couples or something.</p>
                    </div>
                  </div>
                  <div className="Buttons">
                    <div>
                      <button
                        style={{ borderRadius: "8px" }}
                        className="btn btn-outline-white"
                      >
                        Learn more
                      </button>
                    </div>
                    <div>
                      <Link
                        to="/signup"
                        className="btn btn-back"
                        style={{ borderRadius: "8px" }}
                      >
                        Get Access
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="PGT paddingTop">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="blogbtnflex">
                <div>
                  <div className="content">
                    <div className="Heading">
                      <h4>Comparing Orchid to traditional PGT</h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Orchid offers several advantages over existing PGT
                        methodologies. These include faster turnaround times,
                        the ability to detect conditions that other tests may
                        miss, and a comprehensive single screen that includes
                        PGT-A, PGT-M, and gene panels. Moreover, Orchid provides
                        one-on-one genetic counseling for all patients at no
                        additional charge.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Buttons">
                  <div>
                    <button
                      className="btn btn-outline-white"
                      style={{ borderRadius: "8px" }}
                    >
                      Learn more
                    </button>
                  </div>
                  <div>
                    <Link
                      to="/signup"
                      className="btn btn-back"
                      style={{ borderRadius: "8px" }}
                    >
                      Get Access
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px" }}
        >
          <div className="row">
            <div className="col-12 p-0">
              <Slider {...settings}>
                <div className="card">
                  <div className="card-content">
                    <div>
                      <h4>PGT-A</h4>
                    </div>
                    <div>
                      <p>100x more data than traditional PGT-A tests</p>
                      <br />
                      <p>
                        Detect triploidy and molar embryos (complete uniparental
                        isodisomy) that other labs might miss
                      </p>
                      <br />
                      <p>
                        Detect microdeletions + microduplications screened for
                        during pregnancy but missed in traditional PGT
                      </p>
                      <br />
                      <p>Plus all traditional PGT-A features</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-content">
                    <div>
                      <h4>PGT-M</h4>
                    </div>

                    <div>
                      <p>
                        No probe design is necessary, which means faster
                        turnaround time and less coordination
                      </p>
                      <br />
                      <p>Ideal for:</p>
                      <br />
                      <p>
                        Challenging PGT-M cases that may have been denied by
                        other companies
                      </p>
                      <br />
                      <p>
                        Patients with a previous pregnancy/child with a de novo
                        variant
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-content">
                    <div>
                      <h4>Gene Panels</h4>
                    </div>
                    <div>
                      <p>
                        Screen for hundreds of genetic neurodevelopmental
                        conditions, genetic causes of birth defects, and genes
                        associated with hereditary cancer.
                      </p>
                      <br />
                      <p>
                        Includes single gene disorders associated with advancing
                        paternal age
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PGT;
