import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div
          className="container"
          style={{ maxWidth: "1280px", padding: "0px 32px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="bg-gradiant-line"></div>
            </div>
            <div className="col-12">
              <div className="copyright">
                <div className="logo">
                  <img src="/img/svg/logo.svg" />
                  GENEBLOCK
                </div>
                <div className="text">
                  <p>© 2077 Geno Genomics. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1280px", padding: "0px 32px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="footer-links">
                <div className="links">
                  <h4>Product</h4>
                  <ul className="list-inline">
                    <li>
                      <Link to="/">Embryo</Link>
                    </li>

                    <li>
                      <Link to="">Couples</Link> <span>New</span>
                    </li>
                  </ul>
                </div>
                <div className="links">
                  <h4>Science</h4>
                  <ul className="list-inline">
                    <li>
                      <Link to="/">Our mission</Link>
                    </li>
                    <li>
                      <Link to="/science">Science</Link>
                    </li>
                  </ul>
                </div>
                <div className="links">
                  <h4>Company</h4>
                  <ul className="list-inline">
                    <li>
                      <Link to="">Guides</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/career">Careers</Link>
                    </li>
                    <li>
                      <Link to="/science">Principles</Link>
                    </li>
                  </ul>
                </div>
                <div className="links">
                  <h4>For Clinicians</h4>
                  <ul className="list-inline">
                    <li>
                      <Link to="">Guides</Link>
                    </li>
                  </ul>
                </div>
                <div className="links">
                  <h4>Social</h4>
                  <ul className="list-inline">
                    <li>
                      <Link to="">Twitter</Link>
                    </li>
                    <li>
                      <Link to="">LinkedIn</Link>
                    </li>
                    <li>
                      <Link to="">Facebook</Link>
                    </li>
                    <li>
                      <Link to="">AngelList</Link>
                    </li>
                    <li>
                      <Link to="">Dribble</Link>
                    </li>
                  </ul>
                </div>
                <div className="links">
                  <h4>Legal</h4>
                  <ul className="list-inline">
                    <li>
                      <Link to="/terms-condition">Terms</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy</Link>
                    </li>
                    <li>
                      <Link to="">Cookies</Link>
                    </li>
                    <li>
                      <Link to="">Licenses</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1280px", padding: "0px 32px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="footer-content">
                <p>
                  Atomi Financial Group, Inc. dba Compound Planning (“Compound
                  Planning”) is an investment adviser registered with the
                  Securities and Exchange Commission and based out of New York.
                  Certain information on this website may relate to Compound
                  Tax, LLC (“Compound Tax”), a wholly-owned subsidiary of
                  Compound Planning that provides tax consulting and compliance
                  services.
                </p>
                <p>
                  The statement, claim, content, and/or commentary made herein
                  and as found in Compound Planning’s other websites, videos,
                  podcasts, blog posts, articles and other publications or
                  materials, as applicable (collectively, the “Materials”),
                  provides general market commentary or general information
                  and/or expresses the personal opinion of the author or speaker
                  which does not necessarily reflect the views or opinions of
                  Compound Planning.
                </p>
                <p>
                  The content found in the Materials, therefore, should not be
                  interpreted as providing legal, tax, or investment advice or
                  any professional advice for that matter nor is it a
                  solicitation to engage in any particular securities
                  transaction. Compound Planning and its representatives may
                  only transact business or provide investment advice in those
                  states and international jurisdictions where it is registered,
                  filed the required notices, and/or is otherwise excluded or
                  exempted from such registration and/or notice filing
                  requirements.
                </p>
                <p className="m-0">
                  For more information on Compound Planning’s investment
                  advisory services, please refer to Compound Planning’s
                  Brochure Form ADV 2A and Form CRS.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
