import React  from "react";
import { useInView } from "react-intersection-observer";

const Revolution = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  return (
    <>
      <section className="Revolution">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="frame">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle"></div>
                  <div className="bg-label-heading">
                    <label>Customized compliance</label>
                  </div>
                </div>

                <div className="grid">
                  <div className="left-content">
                    <div className="content">
                      <div className="Heading">
                        <h4>Revolutionizing genomic data with Blockchain</h4>
                      </div>
                      <div className="Desc">
                        <p>
                          This cutting-edge approach ensures the integrity and
                          confidentiality of genomic information, addressing
                          long-standing challenges in data storage and access.
                          By leveraging blockchain's decentralized and
                          tamper-proof nature, genomic data becomes more secure
                          and accessible, empowering research and personalized
                          medicine while safeguarding individual privacy.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    <div>
                      <div className="list">
                        <div className="list-text">
                          <h4>
                            <img alt='img'  src="/img/svg/security.svg" />
                            Enhanced Security
                          </h4>
                          <p>
                            Take the reins on which risk profiles you want to
                            support.
                          </p>
                        </div>
                        <div className="list-text">
                          <h4>
                            <img alt='img' src="/img/svg/data.svg" />
                            Improved Data Ownership
                          </h4>
                          <p>
                            Identity requirements that fit your customers and
                            business.
                          </p>
                        </div>
                      </div>
                      <div className="list">
                        <div className="list-text">
                          <h4>
                            <img alt='img' src="/img/svg/facilitated.svg" />
                            Facilitated Data Sharing
                          </h4>
                          <p className="pb-0">
                            Run your own transaction <br /> monitoring in-house.
                          </p>
                        </div>
                        <div className="list-text">
                          <h4>
                            <img alt='img' src="/img/svg/integrity.svg" />
                            Increased Data Integrity
                          </h4>
                          <p className="pb-0">
                            Simple workflows that will help your compliance
                            officer breeze through monthly reporting.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div
                ref={ref}
                className={`cube-anim border-anim ${
                  inView ? "border-anim-add" : ""
                }`}
              >
                <div className="outline-top-left"></div>
                <div className="outline-left-bottom"></div>
                {/* <img  src="/img/svg/cube-fixed.svg" /> */}
                <img alt='img'  src="/img/png/cube.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Revolution;
