import React, { useEffect, useState } from "react";
import moment from "moment";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "react-tabs/style/react-tabs.css";

import { GetArticleData } from "../../../../Api/Article/Article";
import { Config } from "../../../../Config/Config";

const Blogs = () => {
  const [getArticles, setGetArticles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    GetArticleListing(currentPage);
  }, [currentPage]);

  const GetArticleListing = async (currentPage) => {
    try {
      setLoading(true);
      const response = await GetArticleData(currentPage, 3);
      const data = response.data?.data;
      setLoading(false);

      setGetArticles(data);
    } catch (error) {}
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: getArticles.length,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: getArticles.length - 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      <section className="Blogs product-blogs">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="blogbtnflex">
                <div>
                  <div className="sectiontitle">
                    <h4>Our blog</h4>
                  </div>
                  <div className="content">
                    <div className="Heading">
                      <h4>Latest blog posts</h4>
                    </div>
                    <div className="Desc">
                      <p>
                        Tool and strategies modern teams need to help their
                        companies grow.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <Link to="/resources" className="btn btn-back-blue">
                    View all posts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              {isLoading ? (
                <LoaderComponent />
              ) : (
                <Slider {...settings}>
                  {getArticles.map((item) => (
                    <div
                      className="card"
                      onClick={() => navigate(`/blog-detail/${item.id}`)}
                      key={item.id}
                    >
                      <div className="img">
                        <img
                          src={`${Config.baseuUrl}${item?.attributes?.display_image?.data?.attributes?.url}`}
                          className="card-img-top"
                          alt="..."
                        />
                      </div>
                      <div className="card-content">
                        <label>
                          {
                            item?.attributes?.categories?.data?.[0]?.attributes
                              ?.name
                          }
                        </label>
                        <div className="headingicon">
                          <div>
                            <h4>
                              {item?.attributes?.title.slice(0, 50)}
                              {item?.attributes?.title?.length > 50
                                ? "..."
                                : ""}
                            </h4>
                          </div>
                          <div style={{ marginTop: "6px" }}>
                            <img src="/img/svg/arrow-up-right.svg" alt="" />
                          </div>
                        </div>
                        <p>
                          {item?.attributes?.description.slice(0, 100)}
                          {item?.attributes?.description?.length > 100
                            ? "..."
                            : ""}
                        </p>
                        <div className="blogpostinfo">
                          <div className="avatar">
                            <img
                              src={`${Config.baseuUrl}${
                                item?.attributes?.creator?.data?.attributes
                                  ?.creator_image?.data?.attributes?.url ||
                                `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`
                              }`}
                              alt="creator_image"
                              onError={(e) => {
                                e.currentTarget.src = `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`;
                              }}
                            />
                          </div>
                          <div className="text">
                            <h4>
                              {
                                item?.attributes?.creator?.data?.attributes
                                  ?.creator_name
                              }
                            </h4>
                            <p>
                              {moment(item?.attributes?.publishedAt).format(
                                "DD MMM YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {/* <div className="card">
                  <div className="img">
                    <img src="/img/png/blog2.png" />
                  </div>
                  <div className="card-content">
                    <label>Product</label>
                    <div className="headingicon">
                      <div>
                        <h4>Title goes here</h4>
                      </div>
                      <div>
                        <img src="/img/svg/arrow-up-right.svg" />
                      </div>
                    </div>
                    <p>
                      Linear helps streamline software projects, sprints, tasks,
                      and bug tracking. Here’s how to get started.
                    </p>
                    <div className="blogpostinfo">
                      <div className="avatar">
                        <img src="/img/png/team2.png" />
                      </div>
                      <div className="text">
                        <h4>Phoenix Baker</h4>
                        <p>19 Jan 2024</p>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <div className="card">
                  <div className="img">
                    <img src="/img/png/blog3.png" />
                  </div>
                  <div className="card-content">
                    <label>Software Engineering</label>
                    <div className="headingicon">
                      <div>
                        <h4>Title goes here</h4>
                      </div>
                      <div>
                        <img src="/img/svg/arrow-up-right.svg" />
                      </div>
                    </div>
                    <p>
                      The rise of RESTful APIs has been met by a rise in tools
                      for creating, testing, and managing them.
                    </p>
                    <div className="blogpostinfo">
                      <div className="avatar">
                        <img src="/img/png/team3.png" />
                      </div>
                      <div className="text">
                        <h4>Lana Steiner</h4>
                        <p>18 Jan 2024</p>
                      </div>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
const LoaderComponent = () => {
  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <div className="shimmer">
        <div>
          <label className="line"></label>
          <h5 style={{ minHeight: "200px" }} className="line"> </h5>
          <div className="">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <p className="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shimmer-anim"></div>
      </div>
      <div className="shimmer">
        <div>
          <label className="line"></label>
          <h5 style={{ minHeight: "200px" }} className="line"> </h5>
          <div className="">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <p className="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shimmer-anim"></div>
      </div>
      <div className="shimmer">
        <div>
          <label className="line"></label>
          <h5 style={{ minHeight: "200px" }} className="line"> </h5>
          <div className="">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <p className="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shimmer-anim"></div>
      </div>
    </div>
  );
};
