import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
const HowItWorks = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="HowItWorks">
        <div className="pop-img">
          <img src="/img/png/pop.png"alt="img"/>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="d-inline-flex align-items-center">
                <div className="bg-label-circle"></div>
                <div className="bg-label-heading">
                  <label>How it works</label>
                </div>
              </div>
              <div className="content">
                <div className="Heading">
                  <h4>Get the most extensive reports for your embryos</h4>
                </div>
                <div className="Desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc ac ornare tortor. Class aptent taciti sociosqu ad
                    litora torquent per conubia nostra, per inceptos himenaeos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                <div className="card">
                  <div className="img">
                    <img alt='img' src="/img/png/works1.png" />
                  </div>
                  <div className="card-content">
                    <h4>Sequencing</h4>
                    <p>
                      Your IVF doctor orders Orchid on your behalf. An
                      embryologist sends Orchid’s lab ~5 cells from each embryo,
                      the same amount of material required for standard testing.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="img">
                    <img alt='img' src="/img/png/works2.jpeg" />
                  </div>
                  <div className="card-content">
                    <h4>Reporting</h4>
                    <p>
                      Our team of experts reviews the genetic data and reports
                      on the information requested for each embryo in your
                      cycle.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="img">
                    <img alt='img' src="/img/png/works3.png" />
                  </div>
                  <div className="card-content">
                    <h4>Expert review</h4>
                    <p>
                      Meet with a board certified genetic counselor to explain
                      the findings from the report, discuss specific risks
                      related to each embryo tested, and answer any questions.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
