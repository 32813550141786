import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  GetArticleData,
  GetTopArticleData,
} from "../../../../Api/Article/Article";
import { Link } from "react-router-dom";
import moment from "moment";
import { Config } from "../../../../Config/Config";
const Articles = () => {
  const [getArticles, setGetArticles] = useState([]);
  const [getTopArticles, setGetTopArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("View all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    GetArticleListing(currentPage);
  }, [currentPage]);

  useEffect(() => {
    GetTopArticle();
  }, []);

  const GetArticleListing = async (page) => {
    try {
      setLoading(true);
      const response = await GetArticleData(page);
      const data = response.data?.data;
      setLoading(false);

      const uniqueCategories = [
        ...new Set(
          data.map(
            (blog) => blog?.attributes?.categories?.data?.[0]?.attributes?.name
          )
        ),
      ];
      setCategories(["View all", ...uniqueCategories]);

      setGetArticles(data);
      setTotalPages(response.data?.meta?.pagination?.pageCount);
    } catch (error) {}
  };

  const GetTopArticle = async () => {
    try {
      const response = await GetTopArticleData();
      const data = response.data?.data;

      if (data?.length > 0) {
        const firstBlog = data[0];
        setGetTopArticles([firstBlog]);
      }
    } catch (error) {}
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCategoryChange = (index) => {
    setSelectedCategory(categories[index]);
  };

  return (
    <>
      <section className="Articles">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="article-type">
                {getTopArticles.length > 0 ? (
                  getTopArticles.map((items) => (
                    <Link
                      to={`/blog-detail/${items.id}`}
                      className="article-large"
                    >
                      <div
                        className="banner"
                        style={{
                          backgroundImage: `url(${Config.baseuUrl}${items?.attributes?.display_image?.data?.attributes?.url})`,
                        }}
                      >
                        {/* <img
                          src={`${Config.baseuUrl}${items?.attributes?.display_image?.data?.attributes?.url}`}
                          alt="display_image"
                        /> */}
                      </div>
                      <div className="article-content">
                        <div className="Heading">
                          <div>
                            <h4>{items?.attributes?.title}</h4>
                          </div>
                          <div>
                            <img
                              src="/img/svg/arrow-up-right-white.svg"
                              alt="arrow_image"
                            ></img>
                          </div>
                        </div>
                        <div className="Desc">
                          <p>{items?.attributes?.description}</p>
                        </div>
                        <div className="article-detail">
                          <div className="publishdetail">
                            <div className="writtenby">
                              <h5>Written by</h5>
                              <div className="avatar">
                                <img
                                  src={`${Config.baseuUrl}${
                                    items?.attributes?.creator?.data?.attributes
                                      ?.creator_image?.data?.attributes?.url ||
                                    `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`
                                  }`}
                                  alt="creator_image"
                                />
                                <span>
                                  {
                                    items?.attributes?.creator?.data?.attributes
                                      ?.creator_name
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="published">
                              <h5>Published on</h5>
                              <div className="date">
                                <h6>
                                  {moment(
                                    items?.attributes?.publishedAt
                                  ).format("DD MMM YYYY")}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="file-under">
                            <h4>File under</h4>
                            <ul className="list-inline">
                              <li>
                                {
                                  items?.attributes?.categories?.data?.[0]
                                    ?.attributes?.name
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <>
                    {Array.from({ length: 1 }).map((_, index) => (
                      <div className="article-large">
                        <div className="banner shimmer">
                          <div>
                            <label className="line"></label>
                            <p
                              style={{ minHeight: "200px" }}
                              className="line"
                            ></p>
                            <div className="">
                              <div className="p-3">
                                <div className="d-flex align-items-center">
                                  <p className="line"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="shimmer-anim"></div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* <div className="article-large">
                  <div className="banner">
                    <img src="/img/png/articlelarge.png" />
                  </div>
                  <div className="article-content">
                    <div className="Heading">
                      <div>
                        <h4>
                          A board-certified genetic counselor explains what
                          genetic risk means
                        </h4>
                      </div>
                      <div>
                        <img src="/img/svg/arrow-up-right-white.svg"></img>
                      </div>
                    </div>
                    <div className="Desc">
                      <p>
                        Is there a genetic test for heart disease? Alzheimer’s?
                        Diabetes? Discover what the latest science has to offer
                        and how it could help your family.
                      </p>
                    </div>
                    <div className="article-detail">
                      <div className="publishdetail">
                        <div className="writtenby">
                          <h5>Written by</h5>
                          <div className="avatar">
                            <img src="/img/png/amelie.png" />
                            <span>Amélie Laurent</span>
                          </div>
                        </div>
                        <div className="published">
                          <h5>Written by</h5>
                          <div className="date">
                            <h6>10 April 2024</h6>
                          </div>
                        </div>
                      </div>
                      <div className="file-under">
                        <h4>File under</h4>
                        <ul className="list-inline">
                          <li>Design</li>
                          <li>Research</li>
                          <li>Presentation</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="article-tab w-100">
                  <Tabs>
                    <div className="flex-content">
                      <TabList>
                        {categories.map((category, index) => (
                          <Tab
                            key={index}
                            onSelect={() => handleCategoryChange(index)}
                          >
                            {category}
                          </Tab>
                        ))}
                      </TabList>
                      <div>
                        <select className="most-recent">
                          <option>Most recent</option>
                          <option>Select</option>
                        </select>
                      </div>
                    </div>

                    {categories.map((category, index) => (
                      <TabPanel key={index}>
                        <div className="Blog-List blog-cards">
                          {category === "View all" ? (
                            isLoading ? (
                              <LoaderComponent />
                            ) : (
                              getArticles.map((item) => (
                                <Link
                                  to={`/blog-detail/${item.id}`}
                                  className="card"
                                  key={item.id}
                                >
                                  <div className="img">
                                    <img
                                      src={`${Config.baseuUrl}${item?.attributes?.display_image?.data?.attributes?.url}`}
                                      className="card-img-top"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="card-content">
                                    <label>
                                      {
                                        item?.attributes?.categories?.data?.[0]
                                          ?.attributes?.name
                                      }
                                    </label>
                                    <div className="headingicon">
                                      <div>
                                        <h4>
                                          {item?.attributes?.title.slice(0, 50)}
                                          {item?.attributes?.title?.length > 50
                                            ? "..."
                                            : ""}
                                        </h4>
                                      </div>
                                      <div style={{ marginTop: "6px" }}>
                                        <img
                                          src="/img/svg/arrow-up-right.svg"
                                          alt="Arrow Up Right"
                                        />
                                      </div>
                                    </div>
                                    <p>
                                      {item?.attributes?.description.slice(
                                        0,
                                        100
                                      )}
                                      {item?.attributes?.description?.length >
                                      100
                                        ? "..."
                                        : ""}
                                    </p>
                                    <div className="blogpostinfo">
                                      <div className="avatar">
                                        <img
                                          src={`${Config.baseuUrl}${
                                            item?.attributes?.creator?.data
                                              ?.attributes?.creator_image?.data
                                              ?.attributes?.url ||
                                            `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`
                                          }`}
                                          alt="creator_image"
                                          onError={(e) => {
                                            e.currentTarget.src = `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`;
                                          }}
                                        />
                                      </div>
                                      <div className="text">
                                        <h4>
                                          {
                                            item?.attributes?.creator?.data
                                              ?.attributes?.creator_name
                                          }
                                        </h4>
                                        <p>
                                          {moment(
                                            item?.attributes?.publishedAt
                                          ).format("DD MMM YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              ))
                            )
                          ) : isLoading ? (
                            <LoaderComponent />
                          ) : (
                            getArticles
                              .filter(
                                (blog) =>
                                  blog?.attributes?.categories?.data?.[0]
                                    ?.attributes?.name === category
                              )
                              .map((item) => (
                                <Link
                                  to={`/blog-detail/${item.id}`}
                                  className="card"
                                  key={item.id}
                                >
                                  <div className="img">
                                    <img
                                      src={`${Config.baseuUrl}${item?.attributes?.display_image?.data?.attributes?.url}`}
                                      className="card-img-top"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="card-content">
                                    <label>
                                      {
                                        item?.attributes?.categories?.data?.[0]
                                          ?.attributes?.name
                                      }
                                    </label>
                                    <div className="headingicon">
                                      <div>
                                        <h4>
                                          {item?.attributes?.title.slice(0, 50)}
                                          {item?.attributes?.title?.length > 50
                                            ? "..."
                                            : ""}
                                        </h4>
                                      </div>
                                      <div>
                                        <img
                                          src="/img/svg/arrow-up-right.svg"
                                          alt="Arrow Up Right"
                                        />
                                      </div>
                                    </div>
                                    <p>
                                      {item?.attributes?.description.slice(
                                        0,
                                        100
                                      )}
                                      {item?.attributes?.description?.length >
                                      100
                                        ? "..."
                                        : ""}
                                    </p>
                                    <div className="blogpostinfo">
                                      <div className="avatar">
                                        <img
                                          src={`${Config.baseuUrl}${
                                            item?.attributes?.creator?.data
                                              ?.attributes?.creator_image?.data
                                              ?.attributes?.url ||
                                            `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`
                                          }`}
                                          alt="creator_image"
                                          onError={(e) => {
                                            e.currentTarget.src = `${Config.baseuUrl}/uploads/creator_logo_50e0b92492.png`;
                                          }}
                                        />
                                      </div>
                                      <div className="text">
                                        <h4>
                                          {
                                            item?.attributes?.creator?.data
                                              ?.attributes?.creator_name
                                          }
                                        </h4>
                                        <p>
                                          {moment(
                                            item?.attributes?.publishedAt
                                          ).format("DD MMM YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              ))
                          )}
                        </div>
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>
                <div className="custom-pagination">
                  <div
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`arrow-left ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <img src="/img/svg/arrow-left.svg" alt=""/>
                    <h4>Previous</h4>
                  </div>
                  <div>
                    <ul className="list-inline number">
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          key={index + 1}
                          className={currentPage === index + 1 ? "active" : ""}
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </li>
                      ))}
                    </ul>

                    {/* <ul className="list-inline number">
                      <li className="active">1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>...</li>
                      <li>8</li>
                      <li>9</li>
                      <li>10</li>
                    </ul> */}
                  </div>
                  <div
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`arrow-right ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <h4>Next</h4>
                    <img src="/img/svg/arrow-right.svg" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Articles;
const LoaderComponent = () => {
  return (
    <>
      <div className="shimmer">
        <div>
          <label className="line"></label>
          <h5 style={{ minHeight: "200px" }} className="line"></h5>
          <div className="">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <p className="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shimmer-anim"></div>
      </div>
      <div className="shimmer">
        <div>
          <label className="line"></label>
          <h5 style={{ minHeight: "200px" }} className="line"></h5>
          <div className="">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <p className="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shimmer-anim"></div>
      </div>
      <div className="shimmer">
        <div>
          <label className="line"></label>
          <h5 style={{ minHeight: "200px" }} className="line"></h5>
          <div className="">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <p className="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shimmer-anim"></div>
      </div>
    </>
  );
};
