import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const ScreenFeature = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });
  const [ref2, inView1] = useInView({
    triggerOnce: false,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false,
  });

  return (
    <>
      <section className="ScreenFeature">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div
                ref={ref}
                className={`card border-anim ${
                  inView ? "border-anim-add" : ""
                }`}
              >
                <div className="outline-top-left"></div>
                <div className="outline-left-bottom"></div>
                <div className="card-grid">
                  <div className="card-left">
                    <div className="card-content">
                      <div>
                        <div className="d-inline-flex align-items-center">
                          <div className="bg-label-circle"></div>
                          <div className="bg-label-heading">
                            <label>Make an informed decision</label>
                          </div>
                        </div>

                        <div className="Heading">
                          <h4>
                            Screen for more diseases with whole genome analysis
                          </h4>
                        </div>
                      </div>
                      <div>
                        <p className="Desc">
                          Detect genetic errors linked to severe diseases before
                          pregnancy begins. Mitigate risks that could affect a
                          future baby.
                        </p>
                      </div>
                      <div>
                        <p className="Desc">
                          Orchid sequences over 99%* of an embryo’s DNA, while
                          alternatives sequence less than 1%.  With much more
                          data, more risks can be identified.
                        </p>
                      </div>
                      <div className="w-100">
                        <div className="Feature">
                          <div className="d-flex align-items-center">
                            <img src="/img/svg/feature1.svg" />
                            <h5 className="m-0">Feature 1</h5>
                          </div>
                          <div className="d-flex align-items-center">
                            <img src="/img/svg/feature2.svg" />
                            <h5 className="m-0">Feature 2</h5>
                          </div>
                        </div>
                        <div className="Feature m-0">
                          <div className="d-flex align-items-center">
                            <img src="/img/svg/feature3.svg" />
                            <h5 className="m-0">Feature 3</h5>
                          </div>
                          <div className="d-flex align-items-center">
                            <img src="/img/svg/feature4.svg" />
                            <h5 className="m-0">Feature 4</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    ref={ref2}
                    className={`card-right border-anim ${
                      inView1 ? "border-anim-add" : ""
                    }`}
                  >
                    <div className="outline-bottom-left"></div>
                    <div className="outline-left-top"></div>
                    <div className="card-content">
                      <div className="Border-Frame">
                        <img
                          className="Border-Img"
                          src="/img/png/border-frame.png"
                          alt='img'
                        />
                        <div className="position">
                          <div className="detail">
                            <div className="d-inline-flex align-items-center">
                              <div className="bg-label-circle"></div>
                              <div className="bg-label-heading">
                                <label>The difference</label>
                              </div>
                            </div>
                            <h4>99%</h4>
                            <p>more data than competition</p>
                          </div>
                          <div className="img-360">
                            <div className="frame">
                              <div
                                ref={ref3}
                                className={`shade1 ${
                                  inView3 ? "border-bottom-anim" : ""
                                }`}
                              ></div>
                              <div
                                ref={ref3}
                                className={`image ${inView3 ? "draw" : ""}`}
                              >
                                <img src="/img/png/system.png" />
                              </div>
                              <div className="shade2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="wrapperOne">
                <div className="borderOne"></div>
                <div className="main-element"></div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScreenFeature;
