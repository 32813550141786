import Hero from "./section/Hero";
import FAQ from "../../common/FAQ";
import Map from "./section/Map";
import CTA from "../../common/CTA";
import Header from "../../template/Header";
import PageLayout from "../../layout/PageLayout";
const Contact = () => {
  return (
    <>
    <Header/>
      <Hero />
      <FAQ />
      <Map />
      <CTA />
      <PageLayout/>
    </>
  );
};

export default Contact;
