import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import animationData from "../../common/Long base - Signin Lottie.json";
const Login = () => {
  return (
    <>
      <div className="auth-login">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="form">
              <div className="auth-logo">
                <Link to="/">
                  <img src="/img/svg/logo.svg"></img>
                </Link>
                <h4>Welcome back </h4>
                <p>Welcome back! Please enter your details.</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="w-100">
                    <label className="form-label">Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                  </div>

                  <div className="w-100">
                    <label className="form-label">Password*</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="**********"
                    />
                  </div>

                  <div className="d-flex flex-wrap flex-sm-wrap w-100 align-items-center justify-content-between">
                    <div className="form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label
                        className="form-check-label m-0"
                        for="exampleCheck1"
                      >
                        Remember for 30 days
                      </label>
                    </div>
                    <div>
                      <Link to="/forgot-password">Forgot password</Link>
                    </div>
                  </div>
                  <Link to="/">
                    <button type="submit" className="btn send-mess">
                      Sign in
                    </button>
                  </Link>
                  <p className="login-btn">
                    Don’t have an account? <Link to="/signup">Sign up</Link>
                  </p>
                </form>
              </div>
              <p className="copy">© Copyright here 2077</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="content">
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
              />
              {/* <img src="/img/animation/bouncyball.gif" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
