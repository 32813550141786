import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import LeftContent from "./helper/LeftContent";
import RightContent from "./helper/RightContent";
const ReadingEmbryo = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: false, // Only trigger once when the element comes into view
  });

  const lottieRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) + 20
    );
  };

  const handleScroll = () => {
    const sectionElement = lottieRef.current;
    if (sectionElement && isInViewport(sectionElement)) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className="ScreenDisease ReadingEmbryo">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card-full">
                <div className="grid">
                  <div className="left-content">
                    <div className="d-inline-flex align-items-center">
                      <div className="bg-label-circle"></div>
                      <div className="bg-label-heading">
                        <label>Clinically validated</label>
                      </div>
                    </div>
                    <div className="Heading">
                      <h4>Reading 99.6% of an embryo’s genome</h4>
                    </div>
                  </div>
                  <div className="right-content">
                    <p>
                      Use whole genome data to identify monogenic and polygenic
                      diseases. Alternatives miss detecting risks because they
                      do not sequence the whole genome.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="Frame">
                <div>
                  <LeftContent inView={inView} leftRef={ref} />
                </div>
                <div style={{width: '100%', height: '100%'}}>
                  <RightContent
                    rightRef={ref2}
                    inView2={inView2}
                    isPlaying={isPlaying}
                    lottieRef={lottieRef}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReadingEmbryo;
