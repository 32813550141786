const JobList = () => {
  return (
    <>
      <section className="JobList">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="jobs">
                <div className="jobsCat">
                  <h4>Design</h4>
                  <div className="card">
                    <div>
                      <div className="content">
                        <div className="Heading">
                          <h4>Job Title</h4>
                          <ul className="tags list-inline">
                            <li>
                              <div className="bg-label-circle"></div>
                              Tag Here
                            </li>
                          </ul>
                        </div>
                        <div className="Desc">
                          <p>Desc goes here, with more input</p>
                        </div>
                        <ul className="Perks list-inline">
                          <li>
                            <img src="/img/svg/clock.svg" />
                            Full-time
                          </li>
                          <li>
                            <img src="/img/svg/currency-dollar-circle.svg" />
                            80k - 100k
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="country">
                      <img src="/img/png/AU.png" />
                      <h4>Melbourne, Australia</h4>
                    </div>
                  </div>
                  <div className="card">
                    <div>
                      <div className="content">
                        <div className="Heading">
                          <h4>UX Designer</h4>
                          <ul className="tags list-inline">
                            <li>
                              <div className="bg-label-circle"></div>
                              Design
                            </li>
                          </ul>
                        </div>
                        <div className="Desc">
                          <p>
                            We’re looking for a mid-level UX designer to join
                            our team.
                          </p>
                        </div>
                        <ul className="Perks list-inline">
                          <li>
                            <img src="/img/svg/clock.svg" />
                            Full-time
                          </li>
                          <li>
                            <img src="/img/svg/currency-dollar-circle.svg" />
                            80k - 100k
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="country">
                      <img src="/img/png/AU.png" />
                      <h4>Melbourne, Australia</h4>
                    </div>
                  </div>
                </div>
                <div className="jobsCat">
                  <h4>Software Development</h4>
                  <div className="card">
                    <div>
                      <div className="content">
                        <div className="Heading">
                          <h4>Engineering Manager</h4>
                          <ul className="tags list-inline">
                            <li>
                              <div className="bg-label-circle"></div>
                              Design
                            </li>
                          </ul>
                        </div>
                        <div className="Desc">
                          <p>
                            We’re looking for an experienced engineering manager
                            to join our team.
                          </p>
                        </div>
                        <ul className="Perks list-inline">
                          <li>
                            <img src="/img/svg/clock.svg" />
                            Full-time
                          </li>
                          <li>
                            <img src="/img/svg/currency-dollar-circle.svg" />
                            80k - 100k
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="country">
                      <img src="/img/png/AU.png" />
                      <h4>Melbourne, Australia</h4>
                    </div>
                  </div>
                  <div className="card">
                    <div>
                      <div className="content">
                        <div className="Heading">
                          <h4>Frontend Developer</h4>
                          <ul className="tags list-inline">
                            <li>
                              <div className="bg-label-circle"></div>
                              Design
                            </li>
                          </ul>
                        </div>
                        <div className="Desc">
                          <p>
                            We’re looking for an experienced frontend developer
                            to join our team.
                          </p>
                        </div>
                        <ul className="Perks list-inline">
                          <li>
                            <img src="/img/svg/clock.svg" />
                            Full-time
                          </li>
                          <li>
                            <img src="/img/svg/currency-dollar-circle.svg" />
                            80k - 100k
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="country">
                      <img src="/img/png/AU.png" />
                      <h4>Melbourne, Australia</h4>
                    </div>
                  </div>
                  <div className="card">
                    <div>
                      <div className="content">
                        <div className="Heading">
                          <h4>Backend Beveloper</h4>
                          <ul className="tags list-inline">
                            <li>
                              <div className="bg-label-circle"></div>
                              Design
                            </li>
                          </ul>
                        </div>
                        <div className="Desc">
                          <p>
                            We’re looking for an experienced backend developer
                            to join our team.
                          </p>
                        </div>
                        <ul className="Perks list-inline">
                          <li>
                            <img src="/img/svg/clock.svg" />
                            Full-time
                          </li>
                          <li>
                            <img src="/img/svg/currency-dollar-circle.svg" />
                            80k - 100k
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="country">
                      <img src="/img/png/AU.png" />
                      <h4>Melbourne, Australia</h4>
                    </div>
                  </div>
                </div>
                <div className="jobsCat">
                  <h4>Customer Success</h4>

                  <div className="card">
                    <div>
                      <div className="content">
                        <div className="Heading">
                          <h4>Customer Success Manager</h4>
                          <ul className="tags list-inline">
                            <li>
                              <div className="bg-label-circle"></div>
                              Design
                            </li>
                          </ul>
                        </div>
                        <div className="Desc">
                          <p>
                            We’re looking for a mid-level product designer to
                            join our team.
                          </p>
                        </div>
                        <ul className="Perks list-inline">
                          <li>
                            <img src="/img/svg/clock.svg" />
                            Full-time
                          </li>
                          <li>
                            <img src="/img/svg/currency-dollar-circle.svg" />
                            80k - 100k
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="country">
                      <img src="/img/png/AU.png" />
                      <h4>Melbourne, Australia</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobList;
