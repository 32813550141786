import Hero from "./section/Hero";
import Articles from "./section/Articles";
import CTA from "../../common/CTA";
import Header from "../../template/Header";
import PageLayout from "../../layout/PageLayout";
const Resource = () => {
  return (
    <>
      <Header />
      <Hero />
      <Articles />
      <CTA />
      <PageLayout />
    </>
  );
};

export default Resource;
