import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
const CompanySlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="CompanySlider">
        <div
          className="container"
          style={{ maxWidth: "1260px", padding: "0px 32px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h4>Join 4,000+ companies already growing</h4>
              </div>
            </div>
            <div className="col-12">
              <Slider {...settings}>
                <div>
                  <img src="/img/slider/light.svg" alt=""/>
                </div>
                <div>
                  <img src="/img/slider/feather.svg" alt=""/>
                </div>
                <div>
                  <img src="/img/slider/global.svg" alt=""/>
                </div>
                <div>
                  <img src="/img/slider/niet.svg" alt=""/>
                </div>
                <div>
                  <img src="/img/slider/bolt.svg" alt=""/>
                </div>
                <div>
                  <img src="/img/slider/global.svg" alt=""/>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanySlider;
