import React from "react";

const LeftContent = ({ inView, leftRef }) => {
  return (
    <div className="left-content">
      <div
        className={`card border-anim ${inView ? "border-anim-add" : ""}`}
        ref={leftRef}
      >
        <div className="outline-bottom-left"></div>
        <div className="outline-left-top"></div>
        <div className="content">
          <div className="text">
            <div className="heading">
              <h4>Orchid reads 99.6%* of an embryo’s genome</h4>
            </div>
            <div className="desc">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac
                ornare tortor. Class aptent taciti sociosqu ad litora torquent
                per conubia nostra, per inceptos himenaeos.
              </p>
            </div>
          </div>
          <div>
            <div className="list">
              <div className="list-text">
                <h4>Feature 1</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
              <div className="list-text">
                <h4>Feature 3</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
            <div className="list">
              <div className="list-text">
                <h4>Feature 2</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
              <div className="list-text">
                <h4>Feature 4</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="text">
            <div className="heading">
              <h4>
                Orchid correctly detects chromosome counts 99% of the time
              </h4>
            </div>
            <div className="desc">
              <p>
                Orchid’s Preimplantation Genetic Testing for Aneuploidy (PGT-A)
                performance matches or exceeds industry standards, with our
                results matching clinical grade PGT-A results 99% of the time.
                Notably, Orchid’s validated limit of detection for targeted
                microdeletion and microduplications associated with severe
                disease is ~5000x smaller than industry benchmarks, meaning we
                can find smaller issues that other tests miss.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftContent;
