import { Link } from "react-router-dom";
const PricingTable = () => {
  return (
    <>
      <section className="PricingTable">
        <div
          className="container"
          style={{ maxWidth: "1160px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle"></div>
                  <div className="bg-label-heading">
                    <label>Analyze more data + mitigate more risks</label>
                  </div>
                </div>
                <div className="content">
                  <div className="Heading">
                    <h4>Detect more risks</h4>
                  </div>
                  <div className="Desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc ac ornare tortor.
                    </p>
                  </div>
                  <div className="Btns">
                    <div>
                      <button className="btn btn-outline-white">
                        Learn more
                      </button>
                    </div>
                    <div>
                      <Link to="/signup" className="btn btn-back-blue">
                        Get Access
                      </Link>
                      {/* <button className="btn btn-back-blue">Get Access</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: "1280px", padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="w-100 table-demand">
                  <thead>
                    <tr>
                      <td></td>
                      <td>
                        <h4>/GENO</h4>
                      </td>
                      <td>
                        <h5>PGT-A</h5>
                      </td>
                      <td>
                        <h5>PGT-A</h5>
                      </td>
                      <td>
                        <h5>PGT-P</h5>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          Detect extra or missing chromosomes
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          Detect one gene that causes one condition
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          Detect genetic predispositions
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          Detect neurodevelopmental disorders
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          Detect pediatric and adult onset cancers
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          Detect birth defects
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td colSpan={5}>
                        <h1>More keypoints</h1>
                      </td>
                      {/* <td></td><td></td><td></td><td></td> */}
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          X
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          X
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          X
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          X
                          <img src="/img/svg/help-circle.svg" />
                        </h4>
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/minus.svg" />
                      </td>
                      <td>
                        <img src="/img/svg/check-circle.svg" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingTable;
