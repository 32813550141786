import { Link } from "react-router-dom";
const Forgot = () => {
  return (
    <>
      <div className="forgot">
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="auth-logo">
                <img src="/img/svg/key.svg"></img>
                <h4>Forgot password? </h4>
                <p>No worries, we’ll send you reset instructions.</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="w-100">
                    <label className="form-label">Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                  </div>
                  <Link to="/reset-password">
                    <button type="submit" className="btn send-mess">
                      Reset password
                    </button>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontFamily: "TASA Orbiter Text Regular",
                      fontSize: "14px",
                    }}
                    to="/login"
                    className="login-btn"
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src="/img/svg/arrow-left.svg"
                    />
                    Back to log in
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
