import { Link } from "react-router-dom";
const CheckEmail = () => {
  return (
    <>
      <div className="forgot CheckEmail">
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="auth-logo">
                <img src="/img/svg/mail1.svg"></img>
                <h4>Check your email</h4>
                <p>We sent a password reset link to</p>
                <p>olivia@example</p>
              </div>
              <div className="Custom-Form">
                <form>
                  <Link to="/reset-password-success">
                    <button type="submit" className="btn m-0 send-mess">
                      Open email app
                    </button>
                  </Link>
                  <p className="login-btn">
                    Didn’t receive the email? <Link to="">Click to resend</Link>
                  </p>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontFamily: "TASA Orbiter Text Regular",
                      fontSize: "14px",
                    }}
                    to="/login"
                    className="login-btn"
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src="/img/svg/arrow-left.svg"
                    />
                    Back to log in
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckEmail;
