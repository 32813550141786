import { Link } from "react-router-dom";
const ResetPassword = () => {
  return (
    <>
      <div className="forgot ResetPassword">
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="auth-logo">
                <img src="/img/svg/lock.svg"></img>
                <h4>Set new password </h4>
                <p>
                  Your new password must be different to <br></br>previously
                  used passwords.
                </p>
              </div>
              <div className="Custom-Form">
                <form>
                  <div className="w-100">
                    <label className="form-label">Password*</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="*******"
                    />
                  </div>
                  <div className="w-100">
                    <label className="form-label">Confirm Password*</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="*******"
                    />
                  </div>
                  <div className="w-100 phone-text">
                    <p style={{ marginBottom: "12px" }}>
                      <img
                        src="/img/svg/Checkicon.svg"
                        style={{ marginRight: "8px" }}
                      />
                      Must be at least 8 characters
                    </p>
                    <p>
                      <img
                        src="/img/svg/Checkicon.svg"
                        style={{ marginRight: "8px" }}
                      />
                      Must contain one special character
                    </p>
                  </div>
                  <Link to="/check-email">
                    <button type="submit" className="btn send-mess">
                      Reset password
                    </button>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontFamily: "TASA Orbiter Text Regular",
                      fontSize: "14px",
                    }}
                    to="/login"
                    className="login-btn"
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src="/img/svg/arrow-left.svg"
                    />
                    Back to log in
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
